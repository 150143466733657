import gql from 'graphql-tag'

const GET_CART = gql`
query getCart($brand: String){
  general{
    cart(brand:$brand){
      edges{
        node{
          id,
          count,
          owner{
            username
          },
          product{
            id,
            category{
              id
            },
            attachments(type:"P", first:1){
              edges{
                node{
                  file(width:600)
                }
              }
            },
            brand,
            name,
            catalogueNumber,
            priceValue,
            noPrice,
            status
          }
        }
      }
    }
  }
}
`

export default GET_CART