export default {
    "products-in-cart": "Produkty w koszyku",
    "save-as-list": "Zapisz jako listę",
    "generate-file":"Generuj plik",
    "clear-list":"Wyczyść listę",
    "archival-product": "Produkt Archiwalny",
    "delete": "Usuń",
    "my-product-lists": "Moje listy produktów",
    "product-lists": "Listy produktów",
    "products-preview": "Podgląd produktów",
    "add-to-cart": "Dodaj do koszyka",
    "download-file": "Pobierz plik",
    "delete-the-shopping-cart": "Usuwanie koszyka",
    "are-you-sure-you-want-to-delete-the-saved-basket-of-products": "Czy na pewno chcesz usunąć zapisany koszyk produktów?",
    "basket-cleared": "Koszyk wyczyszczony",
    "products-have-been-added-to-the-cart": "Dodano produkty do koszyka",
    "product-list-removed": "Usunięto listę produktów",
    "a-request-to-generate-a-file-has-been-sent": "Wysłano żądanie wygenerowania pliku",
    "the-number-of-products-has-been-changed": "Zmieniono ilość produktów",
    "saved-list": "Lista zapisana",
    "could-not-save-list-for-empty-cart":"Nie można zapisać listy dla pustego koszyka",
    "a-list-with-this-name-already-exists":"Lista z taką nazwą już istnieje",
    "save-products-as-a-list":"Zapisz produkty jako listę",
    "products-saved-on-the-list": "Produkty zapisane na liście",
    "products-cart": "Koszyk produktów",
    "price": "Cena",
    "no-cat": "Nr kat",
    "sum": "Suma"
}