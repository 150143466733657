export default {
  "categories": "Categories",
  "brand-select": "Select brand",
  "products": "Products",
  "favourite-products": "Favourite products",
  "export-templates": "Export templates",
  "cart": "Cart",
  "profile": "My profile",
  "report-bug": "Report bug",
  "logout": "Logout",
  'terms-of-service': 'Terms of service',
  'archive': 'Archive',
  'no-products': 'Archive is empty',
  'products-cart': 'Products Cart'
}