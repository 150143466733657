import gql from 'graphql-tag';

const GET_MODELS = gql`
query getModels($id: ID) {
  general {
    models(id: $id) {
      name
    }
  }
}
`

export default GET_MODELS
