import React, { useEffect, useContext, useState} from 'react';
import {Query, useQuery, useMutation} from "react-apollo";
import GET_PRODUCTS from "../../gql/queries/GET_PRODUCTS";
import styled from 'styled-components'
import AvatarPlaceholder from "../AvatarPlaceholder";
import {Link} from 'react-router-dom';
import Filters from "../Filters";
import FilterContext from "../../context/filter/FilterContext";
import * as EfficiencyIcons from '../../images/efficiencyIcons';
import CenteredSpin from "../../components/_styledComponents/CenteredSpin/CenteredSpin";
import {
    LightButton,
    StyledDrawer,
    StyledListItem,
    StyledPrice,
    StyledDiv,
    StyledPromoPrice, PromoDescription, ArchiveLabelProduct
} from "../_styledComponents";
import {Icon, Button, Popover} from "antd";
import {ShoppingCartOutlined, InfoCircleOutlined} from '@ant-design/icons';
import WindowWidthContext from "../../context/windowWidth/WindowWidthContext";
import {withTranslation} from "react-i18next";
import Cookies from 'universal-cookie';
import GET_VALIDITY_DATE_BRAND from "../../gql/queries/GET_GLOBAL_VALIDITY_DATE";
import ThemeContext from "../../context/themeContext/themeContext";
import moment from "moment";
import ArchiveContext from "../../context/archive/ArchiveContext";
import FavouriteProductSelector from '../FavouriteProductSelector';
import GET_ALL_FAVOURITE_GROUPS from "../../gql/queries/GET_ALL_FAVOURITE_GROUPS";
import ComparsionProductSelector from "../ComparsionProductSelector";
import ComparsionPreview from "../ComparsionPreview";

const drawerConfig = {
    mask: false,
    closable: false,
    width: '100vw',
    getContainer: '#drawerRoot',
    zIndex: 1,
};

const totalCount = quantity => {
    if (quantity === 0 || quantity >= 5) {
        return `${quantity} produktów`;
    } else if (quantity === 1) {
        return `${quantity} produkt`;
    } else if (quantity >= 2 && quantity <= 4) {
        return `${quantity} produkty`;
    }
};

const EfficiencyBar = styled.div`
    position: absolute;
    right: 1%;
    bottom: 25px;
    max-height: 50px;
    svg {
      max-height: 50px;
      max-width: 25px;
      margin-right: 5px;
    }
`;

const NoPriceComment = styled.span`
  opacity: 0.6;
  font-weight: 600;
  max-width: calc(100% - 65px);
`;

const ValidityDateSpan = styled.span`
    display: block;
    text-align: center;
    font-size: 0.8rem;
    color: ${({theme}) => theme.color.primaryColor};
    padding-right: 20px;
    margin-bottom: 15px;
    
    &:hover {
      color: ${({theme}) => theme.color.primaryColor};
    }
    
`;

const ProductsList = ({categoryId, name, t}) => {
    const cookies = new Cookies();
    const {state} = useContext(FilterContext);
    const {brand} = useContext(ThemeContext);
    const {windowWidth} = useContext(WindowWidthContext);
    const {openArchive} = useContext(ArchiveContext);
    const [queryVariables, setQueryVariables] = useState({
      status: openArchive ? "A" : "P",
      first: 10,
      brand: cookies.get('selectedBrand')
    });
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [favouriteProducts, setFavourite] = useState([])
    const [comparedProducts, setComparsion] = useState([])
    const StyledSpan = styled.span`
      color: ${({theme}) => theme.color.primaryColor} !important;
      ${({theme}) => theme.mq.big_desktop`
        padding-left: 30px;
      `}
    `;
    const {data, fetchMore, refetch, loading} = useQuery(
      GET_PRODUCTS, { variables: {...queryVariables}}
    );
    const {data: favouriteGroups} = useQuery(GET_ALL_FAVOURITE_GROUPS, {variables: {brand: brand}})
   useEffect(() => setQueryVariables({
       ...queryVariables,
       status: openArchive ? "A" : "P",
   }), [openArchive])

   useEffect(() => {if (refetch) refetch();}, [queryVariables.params, openArchive]);

   useEffect(() => {
     let queryVariablesTmp = {
       status: queryVariables.status,
       first: queryVariables.first,
       brand: queryVariables.brand,
       ...state,
     };
     if (categoryId.category_id === 'new') {
       delete queryVariablesTmp.discount;
       queryVariablesTmp.new = true;
       queryVariablesTmp.mainCategory = categoryId.id;
     }
     else {
       delete queryVariablesTmp.new;
       delete queryVariablesTmp.discount;
       queryVariablesTmp.mainCategory = categoryId.category_id;
      }
     setQueryVariables(queryVariablesTmp);
   }, [state, categoryId.category_id]);

   const loadMoreProducts = (endCursor) => {
     fetchMore({
       variables: {first: 10, after: endCursor},
       updateQuery: (prev, { fetchMoreResult, ...rest }) => {
         if (!fetchMoreResult) return prev;
         const prevProducts = prev.general.products.edges;
         fetchMoreResult.general.products.edges.unshift(...prevProducts);
         return {...fetchMoreResult}
       },
     });
   };

    const showDrawer = () => setDrawerVisible(true);

    if (loading) return <CenteredSpin width='100%' height='30vh'/>;
    if (!data) return null;
    const count = data.general.products.totalCount;
    const endCursor = data.general.products.pageInfo.endCursor;
    const product = data.general.products.edges.map(e => {
      const file = e.node.attachments.edges.map(f => f);
      const price = e.node.currentPrice;
      let selectedFavouriteGroups
      try{
        selectedFavouriteGroups = e.node.favouritesproductsSet.edges[0].node.favouritesgroupsSet.edges
      } catch(e){
        selectedFavouriteGroups = []
      }
      let favouriteGroupsVar;
        try{
          favouriteGroupsVar = favouriteGroups.general.favouriteGroups.edges
        }catch (e) {
            favouriteGroupsVar=[]
        }
      const previousPrice = e.node.previousPrice;
      const previousPriceModified = previousPrice !== null ? new Number(previousPrice.value).toLocaleString('pl-PL') : null;
      const modifiedPrice = price ? new Number(price.value).toLocaleString('pl-PL') : null;
      const currentIsPromo = price !== null ? price.promotion : null
      return (
          <>
          {openArchive && <ArchiveLabelProduct brand={brand}>Produkt archiwalny</ArchiveLabelProduct>}
      <div style={{display: "flex"}}>
          <FavouriteProductSelector favouriteGroups={favouriteGroupsVar} selectedFavouriteGroups={selectedFavouriteGroups} productId={e.node.id} favouriteStatus={e.node.isInFavourites}/>
          <ComparsionProductSelector  comparisonStatus={false} setComparsion={setComparsion} comparedProducts={comparedProducts} productId={e.node.id} />
      </div>
        <Link to={`/product/${categoryId.category_id}/${e.node.id}`} key={e.node.id}>
          <StyledListItem>
            <div style={{display: 'flex'}}>
                <div style={{position: 'relative'}}>
                    <AvatarPlaceholder file={file} category={categoryId}/>
                    {windowWidth !== 'desktop' && previousPrice !== null && currentIsPromo ?
                      <PromoDescription className={'promo product-list'}>
                <span className={'bold-price'}>{modifiedPrice}</span> <span> PLN netto</span>
                        </PromoDescription> : null

                    }
                </div>
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '0 5px', color: 'rgba(0, 0, 0, .65)', width: '100%'}}>
                  {/*{openArchive && <ArchiveLabelProduct brand={brand}>Produkt archiwalny</ArchiveLabelProduct>}*/}
                <h3>{e.node.name}</h3>
                <p style={{
                  width: '80%',
                  fontSize: '14px',
                  lineHeight: '14px'
                }}>
                  {`${t('catalogue-number')}: ${e.node.catalogueNumber}`}
                </p>
                <p style={{
                  width: '80%',
                  fontSize: '14px',
                  lineHeight: '14px'
                }}>
                  {e.node.descriptionShort && e.node.descriptionShort }
                </p>
                  <div style={{position: 'relative', marginTop: '30px', height: '50px', }}>
                      <div style={{display: 'flex', alignItems: 'center', marginRight: '50px'}}>
                                  {(e.node.noPrice || !price)
                                ?
                              <NoPriceComment>{e.node.noPriceComment}</NoPriceComment>

                                : previousPrice !== null && currentIsPromo  ?
                                            <StyledPromoPrice themeColor={({theme}) => theme[brand].primaryColor}>
                                                                        {`${previousPriceModified} PLN netto`}
                                                <span className={'promo-line'} themeColor={({theme}) => theme[brand].promoLabel}></span>
                                            </StyledPromoPrice> :
                              <StyledPrice colorSet={({theme}) => theme.color.primaryColor} style={{textAlign: 'left', display: 'inline'}}>
                                {`${modifiedPrice} PLN netto`}
                              </StyledPrice>
                            }
                              {
                                  previousPrice !== null && windowWidth !== 'mobile' && currentIsPromo ?
                                         <PromoDescription className={'promo'}>
                                             <span className={'price-bold'}>{modifiedPrice}</span><span>PLN netto</span>
                                        </PromoDescription>
                                 : null
                              }
                      </div>
                  </div>
                <EfficiencyBar>
                  {e.node.efficiencyImages.map((name, index) => {
                    const component = EfficiencyIcons[name];
                    return component && component.render({key: index})
                  })}
                </EfficiencyBar>
              </div>
            </div>
              {
                  previousPrice !== null && windowWidth !== 'mobile' && currentIsPromo ?
                      <div style={{marginTop:"1em"}}>
                          <small>
                              Najniższa ostatnia cena: {e.node.lastLowestPrice} PLN
                              <Popover content="Jest to najniższa cena produktu w ciągu ostatnich 30 dni przed zastosowaniem obniżki ceny" trigger="hover">
                                  <InfoCircleOutlined style={{marginLeft:".25em", fontSize: "1.5em"}} />
                              </Popover>
                          </small>
                      </div>
                      : null
              }
              {
                  previousPrice !== null && windowWidth === 'mobile' && currentIsPromo ?
                      <div style={{marginTop:"1em"}}>
                          <small>
                              <p>Najniższa ostatnia cena: {e.node.lastLowestPrice} PLN<br/>
                                  Jest to najniższa cena produktu w ciągu ostatnich 30 dni przed zastosowaniem obniżki ceny</p>
                          </small>
                      </div>
                      : null
              }
          </StyledListItem>
        </Link>
          </>
      )
    });
    return (
      <div style={{flexDirection: 'column'}}>

        <p style={{textAlign: 'center', fontSize: '1rem', marginBottom: '5px'}}>
            {name}
        </p>
            <Query query={GET_VALIDITY_DATE_BRAND}  variables={{ brand }}>
                {({data, loading}) => {
                     if (!data) return null;
                     if (loading) return <CenteredSpin/>
                     const date = data.general.priceValidFrom.edges.length ? data.general.priceValidFrom.edges[0].node.validFrom : null;
                     const formatedDate = date !== null && moment(date, 'YYYY-mm-DD').format('DD-mm-YYYY') ;

                     return (
                         formatedDate &&    <ValidityDateSpan>
                             {`${t('dateFrom')} ${formatedDate}`}
                         </ValidityDateSpan>
                     )
                 }}
            </Query>
          <StyledDiv>
            <StyledSpan>{totalCount(count)}</StyledSpan>
            {windowWidth === 'mobile' ? <Icon style={{fontSize: '25px'}} type="control" onClick={showDrawer}/> : null}
          </StyledDiv>
          <ComparsionPreview comparedProducts={comparedProducts} categoryId={categoryId} />
        <div className='container'>
          {windowWidth === 'mobile' ?
            <>
              <StyledDrawer {...drawerConfig}
                            placement="right"
                            visible={drawerVisible}
              >
                <Filters id={categoryId.category_id === 'new' ? categoryId.id : categoryId.category_id }
                         setVisible={setDrawerVisible}
                />
              </StyledDrawer>
            </>
            : null
          }
          <ul>{product}
            {count < 5 || count === product.length ? null
                :
              <LightButton className='center'
                           style={{marginTop: '15px'}}
                           themeColor={({theme}) => theme.color.primaryColor}
                           onClick={() =>  loadMoreProducts(endCursor)}
              >
                Załaduj więcej
              </LightButton>
            }
          </ul>
        </div>
      </div>
  )


};

export default withTranslation('productsListContainer')(ProductsList);
