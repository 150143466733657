import React from 'react';
import GET_PARAMETERS from '../../gql/queries/GET_PARAMETERS';
import {withTranslation} from 'react-i18next';
import {Query} from "react-apollo";
import styled from 'styled-components';
import {Spin, Table} from 'antd';

const columns = t => [
    {
        dataIndex: 'name',
        key: 'name',
        width: '50%',
    },
    {
        title: t('value'),
        dataIndex: 'value',
        key: 'value',
        className: 'table-value',
    }
];

const ParametersPanelQuery = ({id, type, t}) => {

    const StyledTable = styled(Table)`
    .table-row:nth-child(even) {   
      background-color: ${({theme}) => theme.color.primaryColorLight} !important;
      
         ${({theme}) => theme.mq.big_desktop`
              background-color: ${({theme}) => theme.color.table} !important;
    `}
    }
    
    .table-row:hover > td {
      background-color: unset !important;
    }
    
    .table-row > td {
      border-bottom: none !important;
    }
    
    .table-value {
      text-align: center;
      font-weight: 700;
    }
    
    &.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
       ${({theme}) => theme.mq.big_desktop`
            padding: 10px 15px;
    `}
    }
    
    
    
    
     ${({theme}) => theme.mq.big_desktop`
        .ant-table table {
            text-align: right;
             font-size: 12px !important;
         }
          .table-value {
              text-align: left;
               font-size: 12px !important;
            }
    `}
`;

    return (
        <Query query={GET_PARAMETERS} variables={{id: id, type: type}}>
            {({data, loading}) => {
                if (!data) return null;
                if (loading) return <Spin/>;
                let dataSource = data.general.products.edges[0].node.productparameterSet.edges.map((p, index) => {
                    let value = '';
                    const unit = p.node.parameter.unit;
                    switch (p.node.parameter.type) {
                        case "N":
                            value = p.node.numberValue;
                           break;
                        case "B":
                            value = p.node.numberValue === 1 ? `${t('yes')}` : `${t('no')}`;
                            break;
                        case "T":
                        case "C":
                            value = p.node.textValue;
                            break;
                        case "A":
                            value = p.node.arrayValue ? p.node.arrayValue.join(', ') : value;
                            break;
                        case "R":
                            value = `${p.node.numberValue} - ${p.node.numberValueTo}`;
                            break;

                    }
                    if (unit && value !== ''){
                        value = `${value} ${unit}`
                    }
                    return (
                        {
                            key: index,
                            name: p.node.parameter.name,
                            value: value
                        }
                    )
                });
                if(type=="L"){
                    dataSource.push({
                        key: dataSource.length+1,
                        name: "Grupa Produktowa",
                        value: data.general.products.edges[0].node.productGroupValue

                    })
                }
                return <StyledTable rowClassName='table-row'
                                    showHeader={false}
                                    dataSource={dataSource}
                                    pagination={false}
                                    columns={columns(t)}
                />
            }}
        </Query>
    )
};

export default withTranslation('productView')(ParametersPanelQuery);