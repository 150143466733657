import gql from 'graphql-tag'

const EDIT_FAVOURITE_PRODUCT_GROUP = gql`
    mutation($input: EditFavouriteProductGroupMutationInput!){
      editFavouriteProductGroupMutation(input: $input){
        group{
          id,
          name
        }
      }
    }
`

export default EDIT_FAVOURITE_PRODUCT_GROUP;