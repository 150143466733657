import gql from 'graphql-tag';

const ADD_PRODUCT_TO_CART = gql`
    mutation($input: AddProductToCartInput!){
        addProductToCart(input: $input){
            added
        }
    }
`

export default ADD_PRODUCT_TO_CART