import gql from 'graphql-tag';

const DELETE_USER_COMMENT = gql`
mutation($input: DeleteUserCommentMutationInput!){
    deleteUserCommentMutation(input:$input){
        deleteStatus
    }
}
`

export default DELETE_USER_COMMENT;