import React, {useEffect, useState, useContext} from 'react';
import {Icon, Slider, Row, Input} from "antd";
import {
  FiltersContainer,
  StyledDivider,
  StrongButton,
  LightButton,
  ButtonsContainer,
} from "../_styledComponents";
import {Query, withApollo} from "react-apollo";
import GET_FILTERS from "../../gql/queries/GET_FILTERS";
import GET_MODELS from "../../gql/queries/GET_MODELS";
import Cookies from 'universal-cookie';
import TagsWrapper from '../TagsWrapper';
import RangeFilter from "../RangeFilter";
import MAX_PRICE_QUERY from "../../gql/queries/MAX_PRICE_QUERY";
import {withTranslation} from "react-i18next";
import styled from "styled-components";
import WindowWidthContext from "../../context/windowWidth/WindowWidthContext";
import FilterContext from "../../context/filter/FilterContext";
import CenteredSpin from "../_styledComponents/CenteredSpin/CenteredSpin";
import ArchiveContext from "../../context/archive/ArchiveContext";

const cookies = new Cookies();

const StyledInput = styled(Input)`
    {
        max-width: 500px
    }
`;

const Filters = ({id, setVisible, client, t}) => {
  const {windowWidth} = useContext(WindowWidthContext);
  const {
    state,
    dispatch,
    applyFiltersAction,
    clearFiltersAction,
    contextCategoryId,
    setContextCategoryId,
  } = useContext(FilterContext);
  const [StyledSliderWrapper, setStyledSliderWrapper] = useState(styled.div`{}`);
  const [allFilters, setAllFilters] = useState([]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [maxPriceValue, setMaxPriceValue] = useState(0);
  const [catalogueNumber, setCatalogueNumber] = useState('');
  const [modelsFilter, setModelsFilter] = useState({});
  const [categoryAvailableModels, setCategoryAvailableModels] = useState([])
  const [status, setStatus] = useState('');
  const {openArchive, setOpenArchive} = useContext(ArchiveContext);

  useEffect(() => {
    if (openArchive) setStatus('A');
    else setStatus('P');
  }, [openArchive])

  function getMaxPrice() {
    client.query({
      query: MAX_PRICE_QUERY,
      variables: {
        filter: id,
        brand: cookies.get('selectedBrand')
      }
    }).then(res => {
      let maxPriceResult = res.data.general.maxPrice;
      maxPriceResult = maxPriceResult? maxPriceResult : 1000;
      if (id !== contextCategoryId || !state.maxPrice) setMaxPrice(maxPriceResult);
      setMaxPriceValue(maxPriceResult);
    });
  }

  function getModels(){
    client.query({
        query: GET_MODELS,
        variables: {
          "id": id
        }
    }).then(res => {
      const modelsNames = res.data.general.models.map(model => model.name);
      setCategoryAvailableModels(modelsNames)
    })
  }

  useEffect(() => {
    getMaxPrice();
    getModels()
    setStyledSliderWrapper(styled.div`
      {
        margin-right: 10%;
        margin-left: 10%;
        .ant-slider-track.ant-slider-track-1 {
          background-color: ${({theme}) => theme.color.primaryColor} !important
        }
      }
    `);
    if (id === contextCategoryId) {
      if (state.minPrice) setMinPrice(state.minPrice);
      if (state.maxPrice) setMaxPrice(state.maxPrice);
      if (state.catalogueNumber) setCatalogueNumber(state.catalogueNumber);
      if (state.params && state.params.length !== 0) setAllFilters(state.params);
      if (state.models && state.models.length !== 0) setModelsFilter(state.models[0]);

    }else {
      setMinPrice(0);
      setMaxPrice(maxPriceValue);
      setCatalogueNumber('');
      setAllFilters([]);
      setModelsFilter({});
      dispatch(clearFiltersAction());
    }

  }, [id]);

  const clearFilters = () => {
    setCatalogueNumber('');
    setMinPrice(0);
    setMaxPrice(maxPrice);
    setAllFilters([]);
    setModelsFilter([]);
    dispatch(clearFiltersAction());
  };

  const applyFilters = () => {
    let queryVariables = {};
    setContextCategoryId(id);
    if (allFilters.length > 0) queryVariables.params = allFilters;
    if (minPrice) queryVariables.minPrice = minPrice;
    if (maxPrice) queryVariables.maxPrice = maxPrice || maxPriceValue;
    if (catalogueNumber) queryVariables.catalogueNumber = catalogueNumber;
    if (modelsFilter.models && !!modelsFilter.models.length) queryVariables.models = [modelsFilter];
     queryVariables.status = status;
    dispatch(applyFiltersAction(queryVariables));
  };

  const onClose = () => {
    setVisible(false);
  };

  const changeFiltersModels = (modelFilters) => {
   setModelsFilter(modelFilters)
  }

  const changeFilters = (parameterFilter, type) => {
     const allFiltersCopy = Array.from(allFilters);
    let filter = {paramId: parameterFilter.paramId};
    if (type === 'NR') {
      if (parameterFilter.values[0]) filter.minNumberValue = parameterFilter.values[0];
      if (parameterFilter.values[1]) filter.maxNumberValue = parameterFilter.values[1];
      filter.valueType = 'NR';
    }
    else {
      filter.values = parameterFilter.values;
      filter.valueType = type;
    }
    const paramIdx = allFiltersCopy.findIndex(elem => elem.paramId === filter.paramId);
    if (paramIdx >= 0) {
      if (parameterFilter.values && parameterFilter.values.length === 0) allFiltersCopy.splice(paramIdx, 1);
      else {
        if (type === 'NR') allFiltersCopy[paramIdx] = filter;
        else allFiltersCopy[paramIdx].values = filter.values;
      }
    }
    else if (parameterFilter.values && parameterFilter.values.length > 0) allFiltersCopy.push({...filter});
    setAllFilters(allFiltersCopy);
  };

  return (
    <FiltersContainer>
      {windowWidth === 'mobile' ?
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: '10px'
        }}>
          <StrongButton onClick={clearFilters}
                        themeColor={({theme}) => theme.color.primaryColor}
          >
            Wyczyść filtry
          </StrongButton>
          <Icon type='close' onClick={onClose}/>
        </div>
      : null

      }
      {
          id !== 'new' &&
          <Query query={GET_FILTERS} variables={{id: id, type: "C,A"}}>
            {({data, loading}) => {
              if (loading) return <CenteredSpin width='100%' height='30vh'/>;
              if (!data) return null;
              const parameters = data.general.categoryParameterFilters.edges;
              //TODO: TO delete if customer not want select where he need models and where not
              // const parametersWithModels = parameters.filter(({node}) => {
              //     return node.parameter.models !== null
              // });
              // const models = parametersWithModels.map((item) => {
              //     return item.node.parameter.models
              // });
              return (
                  <>
                  {categoryAvailableModels.length ?
                       <>
                          <h3>Modele</h3>
                          <TagsWrapper
                              choices={categoryAvailableModels}
                              updateFilters={(e) => changeFiltersModels(e)}
                              initialValues={modelsFilter !== {} && modelsFilter.models}
                              isModel={true}/>
                         <StyledDivider/>
                        </>
                      : null}
               {parameters.map((edge, idx) => {
                const parameterFilter = edge.node.parameter;
                const initialValue = allFilters.find(elem => elem.paramId === parameterFilter.id);
               return (
                  <div key={idx} style={{marginBottom: '10px'}}>

                    <h3 style={{marginBottom: 0}}>{parameterFilter.name} {parameterFilter.unit && `[${parameterFilter.unit}]`}</h3>
                    <StyledDivider/>
                    {['N', 'R'].indexOf(parameterFilter.type) >= 0 ?
                      <RangeFilter id={parameterFilter.id}
                                   initialValues={initialValue && [initialValue.minNumberValue, initialValue.maxNumberValue]}
                                   updateFilters={(e) => changeFilters(e, 'NR')}
                      />
                        :
                      <TagsWrapper id={parameterFilter.id}
                                   initialValues={initialValue && initialValue.values}
                                   choices={parameterFilter.choices}
                                   updateFilters={(e) => changeFilters(e, parameterFilter.type)}
                                   isModel={false}
                      />
                    }
                  </div>
                )
              })}

                  </>
              )
            }}

          </Query>

        }

        <div style={{marginBottom: '10px'}}>
          <h3 style={{marginBottom: '0'}}>{t('price')}:</h3>
          <StyledDivider/>
          <Row style={{fontSize: '20px', textAlign: 'center'}}>
            {`${minPrice}zł - ${maxPrice}zł`}
          </Row>
          <StyledSliderWrapper>
            <Slider range
                    defaultValue={[minPrice, maxPrice]}
                    min={0}
                    max={maxPriceValue}
                    onChange={(value) => {
                      setMinPrice(value[0]);
                      setMaxPrice(value[1]);
                    }}
                    value={[minPrice, maxPrice]}
            />
          </StyledSliderWrapper>
        </div>
        <div style={{marginBottom: '10px'}}>
          <h3 style={{marginBottom: '0'}}>{t('catalogue-number')}:</h3>
          <StyledDivider/>
          <StyledInput value={catalogueNumber}
                       onChange={(e) => setCatalogueNumber(e.target.value)}
          />
        </div>
        <ButtonsContainer>
        <StrongButton className='filters'
                      themeColor={({theme}) => theme.color.primaryColor}
                      onClick={() => {
                        setVisible(false);
                        applyFilters();
                      }}
        >
          {t('filter')}
        </StrongButton>
        {windowWidth === 'desktop' ?
          <LightButton className='filters'
                       onClick={clearFilters}
                       themeColor={({theme}) => theme.color.primaryColor}
          >
            {t('clear-filters')}
          </LightButton>
        : null}
      </ButtonsContainer>
    </FiltersContainer>
  );
};

export default withApollo(withTranslation('search')(Filters));
