import gql from 'graphql-tag'

const GET_COMPARSION_PRODUCTS= gql`
query GetComparsionProducts($id: [ID], $type: String, $count:Int) {
  general {
    productsComparsionList(id: $id, first:$count, orderBy:"id") {
      edges {
        node {
          name
          ean
          catalogueNumber
          id
          status
          descriptionShort
          attachments(type: "P") {
            edges {
              node {
                file (width: 500)
              }
            }
          }
          previousPrice {
            id
            value
            promotion
          }
          currentPrice {
            id
            value
            promotion
          }
          noPrice
          noPriceComment,
          productparameterSet(parameter_ParameterType: $type, orderBy:"parameter__group"){
            edges{
              node{
                id,
                textValue,
                numberValue,
                numberValueTo,
                arrayValue
                parameter{
                  id,
                  type,
                  unit,
                  name
                }
              }
            }
          }
        }
      }
    }
  }
}
    `

export default GET_COMPARSION_PRODUCTS;