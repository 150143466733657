import gql from 'graphql-tag';

const MANAGE_CART_LIST = gql`
    mutation($input: ManageCartListInput!){
        manageCartList(input: $input){
            deleted,
            restoredToCart,
        }
    }
`

export default MANAGE_CART_LIST;