import gql from 'graphql-tag'

const DELETE_FAVOURITE_GROUP = gql`
    mutation($input: DeleteFavouriteProductGroupMutationInput!){
        deleteFavouriteProductGroupMutation(input:$input){
            status
        }
    }
    `

export default DELETE_FAVOURITE_GROUP