import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {
  Form, Input, Button,
} from 'antd';
import {withApollo} from "react-apollo";
import {withTranslation} from "react-i18next";
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import GET_TOKEN from '../../gql/mutations/GET_TOKEN';
import {ErrorMsg, Icon} from '../_styledComponents';
import GENERAL_USER_DATA from "../../gql/queries/GENERAL_USER_DATA";
import styled from 'styled-components';
import Keycloak from 'keycloak-js'

const Advice = styled.div`
  width: 100%;
  font-size: 0.8em;
  text-align: justify;
  line-height: 1em;
`;

const cookies = new Cookies();

class LoginKeycloak extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: false,
            idTokenParsed: false,
        }
    }

    handleSubmit = e => {
        this.props.client.mutate({
            mutation: GET_TOKEN,
            variables: {
                email: this.state.token,
                password: this.props.brand,
            }
        }).then(({data}) => {
            cookies.set('jwttoken', data.tokenAuth.token, {path: '/'});
            cookies.set('keycloak_token', this.state.token, {path: '/'});
            this.props.client.query({
                query: GENERAL_USER_DATA,
                fetchPolicy: 'network-only',
            }).then(({data}) => {
                cookies.set('role', data.me.user.role, {path: '/'});
                cookies.set('brand', this.props.brand, {path: '/'});
                this.props.redirect(true);
            });
        })
    };

    async componentDidMount() {
        if(this.state.token==false){
            let keycloak
            if(this.props.brand=='vaillant'){
                keycloak = new Keycloak(
                    {
                        "realm": process.env.REACT_APP_KEYCLOAK_REALM_VAILLANT,
                        "url": process.env.REACT_APP_KEYCLOAK_URL,
                        "clientId": process.env.REACT_APP_KEYCLOAK_CLIENTID,
                    }
                );
            } else if(this.props.brand=='sd'){
                keycloak = new Keycloak(
                    {
                        "realm": process.env.REACT_APP_KEYCLOAK_REALM_SD,
                        "url": process.env.REACT_APP_KEYCLOAK_URL,
                        "clientId": process.env.REACT_APP_KEYCLOAK_CLIENTID,
                    }
                );
            }
            // await keycloak.init({onLoad: 'login-required', "redirectUri": 'http://cennik.vaillant-test.mpro.fi/login', "pkceMethod": "S256"})
            await keycloak.init({onLoad: 'login-required', pkceMethod: "S256"})
            this.setState({
                token: keycloak.token,
                idTokenParsed: keycloak.idTokenParsed
            })
        }
    }

    render(){
        this.handleSubmit()
        return(
            <div>
                Trwa przekierowywanie...
            </div>
        )
    }
}

LoginKeycloak.propTypes = {
  form: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const AntLoginForm = Form.create({name: 'horizontal_login'})(LoginKeycloak);
const TranslatedComponent = withTranslation('auth')(AntLoginForm);

export default withApollo(TranslatedComponent);
