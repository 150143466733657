import gql from 'graphql-tag'

const SAVE_CART_AS_LIST = gql`
    mutation($input: SaveCartAsListInput!){
        saveCartAsList(input: $input){
            saved,
            emptyCart,
            duplicate
        }
    }
`

export default SAVE_CART_AS_LIST;