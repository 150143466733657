import React, {useEffect} from 'react';
import {BrowserRouter, Switch, Route, useLocation} from 'react-router-dom';
import {
    Dashboard,
    Login,
    LoginKeycloak,
    Products,
    ConstructTile,
    ProductView,
    BrandSelect,
    ProfileContainer,
    ForgotPassword,
    PasswordReset,
    DataExport,
    TermsOfServiceView,
    NewProducts,
    FavouriteProducts,
    ProductsCart
} from '../views';
import ProductsGridFirstPage from "../views/ProductsGridFirstPage";
import GeneralLayout from "../Layout";
import PrivateRoute from "./_components/PrivateRoute";
import BrandedRoute from "./_components/BrandedRoute";
import routes from './../../config/routes';
import {TransitionGroup, CSSTransition} from "react-transition-group";
import {ThemeContextProvider} from "../../context/themeContext/themeContext";
import ReactGA from 'react-ga';
import Promotions from "../views/Promotions";
import GeneralErrorBoundary from "../../containers/GeneralErrorBoundary";
import ProductsGrid from "../views/ProductsGrid";

const PublicRouter = () => (
    <>
        <Route path={`${routes.SSO}/:brand`} component={LoginKeycloak}/>
        <Route path={routes.LOGIN} component={Login}/>
    </>
);

const BrandRouter = () => (
    <GeneralLayout>
        <PrivateRoute
            exact
            path={[routes.BRAND_SELECT]}
            component={BrandSelect}
            redirect={routes.LOGIN}
            availableFor={['SV', 'EM', 'WS', 'FI',]}
        />
    </GeneralLayout>
);


const PrivateRouter = () => (
    <GeneralLayout>
        <BrandedRoute
            exact
            path={[routes.DASHBOARD]}
            givenComponent={Dashboard}
            brandRedirect={routes.BRAND_SELECT}
            loginRedirect={routes.LOGIN}
            availableFor={['SV', 'EM', 'WS', 'FI',]}
        />
        <Switch>
          <BrandedRoute
                exact
                path={`${routes.PRODUCTS_GRID}`}
                givenComponent={ProductsGridFirstPage}
                brandRedirect={routes.BRAND_SELECT}
                loginRedirect={routes.LOGIN}
                availableFor={['SV', 'EM', 'WS', 'FI',]}
            />
            <BrandedRoute
                exact
                path={`${routes.PRODUCTS_GRID}/:category_id`}
                givenComponent={ProductsGrid}
                brandRedirect={routes.BRAND_SELECT}
                loginRedirect={routes.LOGIN}
                availableFor={['SV', 'EM', 'WS', 'FI',]}
            />
            <BrandedRoute
                exact
                path={`${routes.PRODUCTS_LIST}/:category_id?/:id?`}
                givenComponent={Products}
                brandRedirect={routes.BRAND_SELECT}
                loginRedirect={routes.LOGIN}
                availableFor={['SV', 'EM', 'WS', 'FI',]}
            />
            <BrandedRoute
                exact
                path={`${routes.PRODUCT}/:category_id/:id`}
                givenComponent={ProductView}
                brandRedirect={routes.BRAND_SELECT}
                loginRedirect={routes.LOGIN}
                availableFor={['SV', 'EM', 'WS', 'FI',]}
            />
            <BrandedRoute
                exact
                path={`${routes.PROMOTIONS}`}
                givenComponent={Promotions}
                brandRedirect={routes.BRAND_SELECT}
                loginRedirect={routes.LOGIN}
                availableFor={['SV', 'EM', 'WS', 'FI',]}
            />
            <BrandedRoute
                exact
                path={`${routes.NEW}`}
                givenComponent={NewProducts}
                brandRedirect={routes.BRAND_SELECT}
                loginRedirect={routes.LOGIN}
                availableFor={['SV', 'EM', 'WS', 'FI',]}
            />
            <BrandedRoute
                exact
                path={`${routes.EXPORT_TEMPLATES}`}
                givenComponent={DataExport}
                brandRedirect={routes.BRAND_SELECT}
                loginRedirect={routes.LOGIN}
                availableFor={['SV', 'EM', 'WS',]}
            />
            <Route exact
                    path={[routes.TERMS]}
                    component={TermsOfServiceView}
                    redirect={routes.LOGIN}
                    availableFor={['SV', 'EM', 'WS', 'FI',]}
            />
            <Route exact
                   path={[routes.FAVOURITE_PRODUCTS]}
                   component={FavouriteProducts}
                   redirect={routes.LOGIN}
                   availableFor={['SV', 'EM', 'WS', 'FI',]}
            />
            <Route exact
                   path={[routes.PRODUCTS_CART]}
                   component={ProductsCart}
                   redirect={routes.LOGIN}
                   availableFor={['SV', 'EM', 'WS', 'FI',]}
            />
            <BrandedRoute
                exact
                path='/:subcategory+'
                givenComponent={ConstructTile}
                brandRedirect={routes.BRAND_SELECT}
                loginRedirect={routes.LOGIN}
                availableFor={['SV', 'EM', 'WS', 'FI',]}
            />
        </Switch>
        <BrandedRoute
            exact
            path={`${routes.PROFILE}`}
            givenComponent={ProfileContainer}
            brandRedirect={routes.BRAND_SELECT}
            loginRedirect={routes.LOGIN}
            availableFor={['SV', 'EM', 'WS', 'FI',]}
        />
    </GeneralLayout>
);

const RouterSwitch = () => {
  const location = useLocation();
  useEffect(() => ReactGA.pageview(location.pathname + location.search), [location]);

  return (
    <Switch>
      <Route exact path={[routes.LOGIN]} component={PublicRouter}/>
      <Route exact path={[`${routes.SSO}/:brand`]} component={PublicRouter}/>
      <Route exact path={[routes.BRAND_SELECT]}
             render={() => <BrandRouter />}/>
      <Route exact path={[routes.FORGOT_PASSWORD]} component={ForgotPassword}/>
      <Route exact path={[`${routes.PASSWORD_RESET}/:token`]} component={PasswordReset}/>
      <PrivateRoute component={PrivateRouter}
                    redirect={routes.LOGIN}
                    availableFor={['SV', 'EM', 'WS', 'FI',]}/>
    </Switch>
  )
};

const Router = () => {
    return (
        <BrowserRouter>
            <ThemeContextProvider>
              <GeneralErrorBoundary>
                <TransitionGroup>
                    <CSSTransition
                        timeout={{enter: 900, exit: 900}}
                    >
                        <RouterSwitch/>
                    </CSSTransition>
                </TransitionGroup>
              </GeneralErrorBoundary>
            </ThemeContextProvider>
        </BrowserRouter>
    )
};

export default Router;
