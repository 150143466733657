import gql from 'graphql-tag';

const GET_CART_LIST_PRODUCT = gql`
    query getCartListProduct($cart_id:ID){
      general{
        cartListProduct(cartList: $cart_id){
          edges{
            node{
              id,
              count,
              cartList{
                id
              },
              product{
                name,
                catalogueNumber
              }
            }
          }
        }
      }
    }
`

export default GET_CART_LIST_PRODUCT