import React, {useState, useEffect, useContext} from 'react';
import {Menu, Icon} from 'antd';
import Cookies from 'universal-cookie';
import {withRouter} from 'react-router';
import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types';
import routes from "../../../../config/routes";
import styled from 'styled-components';
import client from "../../../../config/apollo/client";
import GET_MAIN_CATEGORIES from "../../../../gql/queries/GET_MAIN_CATEGORIES";
import {removeCategoriesWithoutProducts} from "../../../../helpers/removeCategoriesWithoutProducts";
import MenuContext from "../../../../context/menu/MenuContext";
import CategoryMenu from "../../../../components/CategoryMenu";
import CenteredSpin from "../../../../components/_styledComponents/CenteredSpin/CenteredSpin";
import {StyledMenuLink} from "../../../../components/_styledComponents";
import ArchiveContext from "../../../../context/archive/ArchiveContext";
import GET_ARCHIVAL_PRODUCTS_CATEGORIES from "../../../../gql/queries/GET_ARCHIVAL_PRODUCTS_CATEGORIES";

const {SubMenu, Item} = Menu;

const StyledSpan = styled.span`
  font-size: 1.25rem;
  position: relative;
  padding-left: 1.75rem;
   
   ${({theme}) => theme.mq.big_desktop`
      font-size: 14px;
`}
`;

const StyledMainIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-60%);
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  right: 5%;
  
   ${({theme}) => theme.mq.big_desktop`
         font-size: 10px !important;
   `}
`;

const StyledMenu = styled(Menu)`
    max-height: ${({theme}) => `calc(100vh - ${theme.layout.headerHeight})!important}`};
    overflow-x: hidden;
    overflow-y: auto;
    transition: .3s;
    border-bottom: 1px solid #e8e8e8;

    .selected-item {
      background-color: ${({theme}) => theme.color.primaryColorLightest} !important;
      border-right: 5px ${({theme}) => theme.color.primaryColor} solid !important;
    }

    .ant-menu-submenu {
      max-width: 1500px;
    }

    .ant-menu-submenu-title, .ant-menu-item {
      white-space: normal;
      display: flex;
      align-items: center;
      line-height: normal !important;
      height: auto !important;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      padding-right: 50px !important;
    }
    
    
    
     ${({theme}) => theme.mq.big_desktop`
        padding-top: 30px !important;
        overflow-y: hidden;
        display: block;
        height: 100%;
    
        ::-webkit-scrollbar {
          width: 5px;
          transition: .5s;
        }
        
        ::-webkit-scrollbar-track {
          background: #f1f1f1; 
        }
         
        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #888; 
        }
        
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #888; 
        }
    `}
      
      
       &:hover {
         ${({theme}) => theme.mq.big_desktop`
              overflow-y: auto;
         `}
      }
      
  `;


const Navigation = ({t, drawerVisible, windowWidth}) => {
  const [ archiveMainCategories, setArchiveMainCategories ] = useState([]);
  const {selectedItem, setSelectedItem, openKeys, setOpenKeys, setOpenKeysLevel} = useContext(MenuContext);
  const {setOpenArchive} = useContext(ArchiveContext);
  const cookies = new Cookies();
  const [exportVisible, setExportVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const roleCode = cookies.get('role');
    if (roleCode === 'SV' || roleCode === 'EM' || roleCode === 'WS')
      setExportVisible(true);
  }, []);

  const handleProductsClick = (section) => {
    if (openKeys.length > 0 && openKeys.includes(section)) {
      setOpenKeys([]);
      setOpenKeysLevel([]);
    } else {
      if (section === 'products') {
         client.query({
          query: GET_MAIN_CATEGORIES,
          variables: {
            brand: cookies.get('selectedBrand')
          }
        }).then((res) => {
          setOpenKeysLevel([]);
        });
      } else {
        setLoading(true)
        client.query({
          query: GET_ARCHIVAL_PRODUCTS_CATEGORIES,
          variables: {
            brand: cookies.get('selectedBrand')
          }
        }).then(({data}) => {
           const categories = data.general.products.edges.map(product => {
                 return product.node.mainCategory
             });
             const filteredCategories = categories.filter((item, i, array) => {
                 return  (
                    i === array.findIndex((t) => (t.id === item.id ))
                 )
             });
          setArchiveMainCategories(filteredCategories);
          setLoading(false)
          setOpenKeysLevel([]);
        });
      }
     setOpenKeys([section])
    }
  };

  const handleItemClick = (item_key) => {
    drawerVisible(false);
    setSelectedItem(item_key);
    setOpenKeys([]);
  };

  const logout = () => {
    cookies.remove('jwttoken', {path: '/'});
    cookies.remove('role', {path: '/'});
    cookies.remove('selectedBrand', {path: '/'});
  };

  const customExpandIcon = (currentMenu) => {
    const isSectionOpen = openKeys.length > 0 && openKeys.includes(currentMenu);
     return <StyledIcon type={isSectionOpen ? "down" : "right"} />
  }

  const handleSelection = (key, isArchive) => {
    setOpenArchive(isArchive)
    setSelectedItem(key);
    drawerVisible(false);
  };

  return (
    <StyledMenu
      mode='inline'
      openKeys={openKeys}
      multiple={false}
    >
      {windowWidth === 'mobile' ? <Item key='brand-select'>
        <StyledMenuLink to={routes.BRAND_SELECT}>
          <StyledSpan><StyledMainIcon type='control'/>{t('brand-select')}</StyledSpan>
        </StyledMenuLink>
      </Item> : null}
      <Item key='categories'
            className={`${selectedItem === 'categories' ? 'selected-item' : ''}`}
            onClick={() => handleItemClick('categories')}
      >
        <StyledMenuLink to={routes.DASHBOARD}>
          <StyledSpan><StyledMainIcon type='appstore'/>{t('categories')}</StyledSpan>
        </StyledMenuLink>
      </Item>
      <Item key='products'
            className={`${selectedItem === 'products' ? 'selected-item' : ''}`}
            onClick={() => handleItemClick('products')}
      >
      <StyledMenuLink to={routes.PRODUCTS_GRID}>
          <StyledSpan><StyledMainIcon type='unordered-list'/>{t('products')}</StyledSpan>
        </StyledMenuLink>
      </Item>
      {exportVisible && <Item key='export-templates'
            className={`${selectedItem === 'export-templates' ? 'selected-item' : ''}`}
            onClick={() => handleItemClick('export-templates')}
      >
        <StyledMenuLink to={routes.EXPORT_TEMPLATES}>
          <StyledSpan><StyledMainIcon type='export'/>{t('export-templates')}</StyledSpan>
        </StyledMenuLink>
      </Item>}
      {/*<Item key='cart'>*/}
      {/*  <NavLink to={routes.CART}>*/}
      {/*    <StyledSpan><StyledMainIcon type='shopping-cart'/>{t('cart')}</StyledSpan>*/}
      {/*  </NavLink>*/}
      {/*</Item>*/}
      {
        windowWidth === 'mobile' ?
          <Item key='profile'
            className={`${selectedItem === 'profile' ? 'selected-item' : ''}`}
            onClick={() => handleItemClick('profile')}
      >
        <StyledMenuLink to={routes.PROFILE}>
          <StyledSpan><StyledMainIcon type='smile'/>{t('profile')}</StyledSpan>
        </StyledMenuLink>
      </Item> : null
      }
      {/*<Item key='report-bug'>*/}
      {/*  <NavLink to={routes.REPORT_BUG}>*/}
      {/*    <StyledSpan><Icon type='dislike'/>{t('report-bug')}</StyledSpan>*/}
      {/*  </NavLink>*/}
      {/*</Item>*/}
      <Item key='terms'
            className={`${selectedItem === 'terms' ? 'selected-item' : ''}`}
            onClick={() => handleItemClick('terms')}
      >
        <StyledMenuLink to={routes.TERMS}>
          <StyledSpan><StyledMainIcon type='file-text'/>{t('terms-of-service')}</StyledSpan>
        </StyledMenuLink>
      </Item>
       <SubMenu key='archive'
                title={<StyledSpan><StyledMainIcon type='database'/>{t('archive')}</StyledSpan>}
                onTitleClick={() => handleProductsClick('archive')}
                expandIcon={() => customExpandIcon('archive')}
                className='categories'
      >
          {
          archiveMainCategories.length ? (
            archiveMainCategories.map(category =>
              <CategoryMenu category={category}
                            key={category.id}
                            windowWidth={windowWidth}
                            handleSelection={handleSelection}
                            isArchive={true}
              />
            )
          ) : loading ? <CenteredSpin/> : <div style={{textAlign: 'center'}}>{t('no-products')}</div>
        }
      </SubMenu>
      <Item key='favourite-products'
            className={`${selectedItem === 'favourite-products' ? 'selected-item' : ''}`}
            onClick={() => handleItemClick('favourite-products')}>
        <StyledMenuLink to={routes.FAVOURITE_PRODUCTS}>
          <StyledSpan><StyledMainIcon type='heart-fill'/>{t('favourite-products')}</StyledSpan>
        </StyledMenuLink>
      </Item>
      <Item key='product-list'
            className={`${selectedItem === 'product-list' ? 'selected-item' : ''}`}
            onClick={() => handleItemClick('product-list')}>
        <StyledMenuLink to={routes.PRODUCTS_CART}>
          <StyledSpan><StyledMainIcon type='shopping-cart'/>{t('products-cart')}</StyledSpan>
        </StyledMenuLink>
      </Item>
      {windowWidth === 'mobile' ? <Item key='logout'>
        <StyledMenuLink onClick={() => logout()} to={routes.DASHBOARD}>
          <StyledSpan><StyledMainIcon type='logout'/>{t('logout')}</StyledSpan>
        </StyledMenuLink>
      </Item> : null
      }
    </StyledMenu>
  );
};

Navigation.defaultProps = {};

Navigation.propTypes = {
  t: PropTypes.func.isRequired,
  drawerVisible: PropTypes.func.isRequired,
};

const TranslatedComponent = withTranslation('navigation')(Navigation);

export default withRouter(TranslatedComponent);
