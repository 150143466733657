import gql from 'graphql-tag';

const DELETE_ALL_PRODUCTS_FROM_CART = gql`
    mutation($input: DeleteAllProductsFromCartInput!){
        deleteAllProductsFromCart(input:$input){
            deleted
        }
    }
`

export default DELETE_ALL_PRODUCTS_FROM_CART;