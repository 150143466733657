import gql from 'graphql-tag';

const DELETE_FROM_FAVOURITE_PRODUCTS = gql`
    mutation($input: DeleteFromFavouriteProductsMutationInput!) {
        deleteFavouriteProductMutation(input: $input){
            product{
                id,
                name
            }
        }
    }
`
export default DELETE_FROM_FAVOURITE_PRODUCTS;