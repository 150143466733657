import gql from 'graphql-tag'

const GET_ALL_FAVOURITE_GROUPS = gql`
    query getAllFavouriteGroups($brand: String){
      general{
        favouriteGroups(brand: $brand) {
          edges {
            node {
              id,
              name
            }
          }
        }
      }
    }
`

export default GET_ALL_FAVOURITE_GROUPS