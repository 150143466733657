import gql from 'graphql-tag';

const ADD_TO_FAVOURITE_PRODUCTS = gql`
    mutation($input: CreateFavouriteProductMutationInput!) {
        createFavouriteProductMutation(input: $input){
            product{
                id,
                name
            }
        }
    }
`

export default ADD_TO_FAVOURITE_PRODUCTS;