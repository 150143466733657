import gql from 'graphql-tag'

const GET_ALL_FAVOURITE_PRODUCTS = gql`
    query getAllFavouriteProducts($brand: String){
        general{
            favouriteProducts(product_Brand: $brand){
                edges{
                    node{
                        product{
                            id,
                            name,
                            descriptionShort,
                            createdAt,
                            catalogueNumber,
                            noPriceComment,
                            usercommentsSet{
                                edges{
                                    node{
                                        id,
                                        comment,
                                        createDate
                                    }
                                }
                            }
                            attachments(type: "P", first: 1) {
                                edges {
                                    node {
                                        file (width:600)
                                    }
                                }
                            },
                            noPrice,
                            status,
                            noPriceComment
                            currentPrice {
                                id
                                value
                                promotion
                            }
                            previousPrice {
                                id
                                value
                                promotion
                            }
                            category {
                                id,
                                name
                            }
                        },
                        favouritesgroupsSet{
                            edges{
                                node{
                                    id,
                                    name,
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
export default GET_ALL_FAVOURITE_PRODUCTS