import React from "react";
import {withApollo} from "react-apollo";
import {Button, Divider, List, Typography, InputNumber, Input, message, Modal} from "antd";
import {Link} from 'react-router-dom';
import ThemeContext from "../../context/themeContext/themeContext";
import {ShoppingCartOutlined,DeleteOutlined, SaveOutlined, InboxOutlined, DownloadOutlined, InfoCircleOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import AvatarPlaceholder from "../AvatarPlaceholder";
import {useQuery, useMutation} from "@apollo/react-hooks";
import {useEffect, useState, useContext} from "react";
import GET_CART from "../../gql/queries/GET_CART";
import MANAGE_PRODUCT_IN_CART from "../../gql/mutations/MANAGE_PRODUCT_IN_CART";
import SAVE_CART_AS_LIST from "../../gql/mutations/SAVE_CART_AS_LIST";
import GET_CART_LIST from "../../gql/queries/GET_CART_LIST";
import MANAGE_CART_LIST from "../../gql/mutations/MANAGE_CART_LIST"
import DELETE_ALL_PRODUCTS_FROM_CART from "../../gql/mutations/DELETE_ALL_PRODUCTS_FROM_CART";
import GET_CART_LIST_PRODUCT from "../../gql/queries/GET_CART_LIST_PRODUCT";
import Cookie from 'universal-cookie';
import {ArchiveLabelProduct} from "../_styledComponents";
import {useTranslation} from "react-i18next";

const cookies = new Cookie();

const {confirm} = Modal;

const ProductsCartContainer = (props) =>{
    const {t, i18n} = useTranslation('product_cart_container');
    const {brand} = useContext(ThemeContext);
    const {loading: cart_loading, error: cart_error, data: cart_data, refetch: getCart} = useQuery(GET_CART,{fetchPolicy: "no-cache", variables:{brand: brand}})
    const {loading: cart_list_loading, error: cart_list_error, data: cart_list_data, refetch: getCartList} = useQuery(GET_CART_LIST, {fetchPolicy: "no-cache", variables:{brand: brand}})
    const {loading: cart_list_product_loading, error: cart_list_product_error, data: cart_list_product_data, refetch: getCartListProduct} = useQuery(GET_CART_LIST_PRODUCT, {fetchPolicy: "no-cache"})
    const [editCountOrDelete, {loading: edit_cart_loading, error: edit_cart_error, data: edit_cart_data}] = useMutation(MANAGE_PRODUCT_IN_CART);
    const [manageCartList, {loading: manage_cart_list_loading, error: manage_cart_list_error,data: manage_cart_list_data}] = useMutation(MANAGE_CART_LIST);
    const [saveCartAsList, {loading: save_cart_loading, error: save_cart_error, data: save_cart_data}] = useMutation(SAVE_CART_AS_LIST);
    const [deleteAllProducts, {loading: delete_all_loading, error: delete_all_error, data: delete_all_data}] = useMutation(DELETE_ALL_PRODUCTS_FROM_CART)
    const [data_products, setDataProducts] = useState([])
    const [cart_lists, setCartLists] = useState([])
    const [summary_price, setSummaryPrice] = useState(0)
    const [show_save_modal_status, showSaveModal] = useState(false)
    const [show_preview_modal_status, showPreviewModal] = useState(false)
    const [preview_modal_data, setPreviewData] = useState([])
    const [name_cart_list, setNameCartList] = useState('')
    const token = cookies.get('jwttoken');
    const deleteCartConfirmShow = (item_id) =>{
        confirm({
            title: t('delete-the-shopping-cart'),
            icon: <ExclamationCircleOutlined />,
            content: t('are-you-sure-you-want-to-delete-the-saved-basket-of-products'),
            onOk(){
                manageCartList({variables:{input:{listId:item_id, restoreToCart: false, generateFile: false, delete: true}}})
            },
            onCancel(){}

        })
    }
    function download(blob, filename) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }
    const download_file = async (id='', name='cart')=> {
        let response = await fetch(`${process.env.REACT_APP_URL_EXPORT_CART}${id}`,
            {
                method: "GET",
                headers: {"Authorization": token}
            }
        )
        response.blob().then(blob => {
            download(blob, name)
        })
    }
    useEffect(()=>{
        try{
            if(delete_all_data.deleteAllProductsFromCart.deleted){
                message.success(t('basket-cleared'))
                getCart()
            }
        }
        catch (e) {

        }
    }, delete_all_data)
    useEffect(()=>{
        try{
            if(manage_cart_list_data.manageCartList.restoredToCart){
                message.success(t('products-have-been-added-to-the-cart'))
                getCart();
            }
            if(manage_cart_list_data.manageCartList.deleted){
                message.success(t('product-list-removed'))
                getCartList();
            }
            if(manage_cart_list_data.manageCartList.generatedFile){
                message.success(t('a-request-to-generate-a-file-has-been-sent'))
            }
        }catch (e){

        }

    }, manage_cart_list_data)
    useEffect(()=>{
        try{
            if(edit_cart_data.manageProductInCart.changed){
                message.success(t('the-number-of-products-has-been-changed'))
                getCart()
                setDataProducts([])
            }else{
                getCart()
            }
        }catch (e){

        }
        try{
            if(cart_data.general.cart.edges){
                let tmp_cart = []
                let tmp_summary_price = 0
                cart_data.general.cart.edges.map(
                    (product)=>{
                        tmp_cart.push({
                            avatar: product.node.product.attachments.edges,
                            name: product.node.product.name,
                            count: product.node.count,
                            cat_no: product.node.product.catalogueNumber,
                            price: product.node.product.priceValue,
                            no_price: product.node.product.noPrice,
                            cart_id: product.node.id,
                            product_id: product.node.product.id,
                            product_category_id: product.node.product.category.id,
                            status: product.node.product.status,
                        })
                        if(product.node.product.priceValue && product.node.product.noPrice===false){
                            tmp_summary_price += product.node.product.priceValue*product.node.count
                        }
                    }
                )
                setDataProducts(tmp_cart)
                setSummaryPrice(tmp_summary_price)
            }
        } catch (e){

        }
    }, [cart_data, edit_cart_data])
    useEffect(()=>{
        try{
            let cart_status = save_cart_data.saveCartAsList
            if(cart_status.saved){
                message.success(t('saved-list'))
                getCart()
                getCartList()
                showSaveModal(false)
            }
            if(cart_status.emptyCart){
                message.error(t('could-not-save-list-for-empty-cart'))
                showSaveModal(false)
            }
            if (cart_status.duplicate) {
                message.error(t('a-list-with-this-name-already-exists'))
                showSaveModal(true)
            }
        } catch (e){

        }
        try{
            if(cart_list_data.general.cartList){
                let tmp_cart_list = []
                cart_list_data.general.cartList.edges.map(
                    (list) =>{
                        tmp_cart_list.push({
                            id: list.node.id,
                            name:list.node.name,
                            summary_price: list.node.summaryPrice
                        })
                    }
                )
                setCartLists(tmp_cart_list)
            }
        } catch (e) {

        }
    }, [cart_list_data, save_cart_data])
    useEffect(()=>{
        try{
            let products_tmp = []
            if(cart_list_product_data.general.cartListProduct.edges){
                cart_list_product_data.general.cartListProduct.edges.map(
                    (e)=>{
                        products_tmp.push({
                                count:e.node.count,
                                name: e.node.product.name,
                                catalogue_number: e.node.product.catalogueNumber,
                                list: e.node.cartList.id
                            }
                        )
                    }
                )
                setPreviewData(products_tmp)
            }
        }catch (e) {

        }
    }, [cart_list_product_data,])
    return(
        <div>
            <Modal title={t('save-products-as-a-list')} onOk={()=>saveCartAsList({variables:{input:{listName: name_cart_list}}})} onCancel={()=>showSaveModal(false)} visible={show_save_modal_status}>
                <Input onChange={(event)=>setNameCartList(event.target.value)} />
            </Modal>
            <Modal title={t('products-saved-on-the-list')} onOk={()=>showPreviewModal(false)} onCancel={()=>showPreviewModal(false)} visible={show_preview_modal_status}>
                {show_preview_modal_status}
                {preview_modal_data.map(
                    (item)=><p>{item.name} ({item.catalogue_number}) - {item.count}szt</p>
                )}
            </Modal>
            <Divider orientation="left">
                {t("products-cart")}
            </Divider>
            <List
                header={<div>{t("products-in-cart")}</div>}
                footer={<div style={{display:"flex", flexDirection:"row", justifyContent: "space-between", flexWrap:"wrap"}}>
                    <div>
                        <Button onClick={()=>showSaveModal(true)}>{t('save-as-list')}</Button>
                        <Button onClick={()=>download_file()}>{t('generate-file')}</Button>
                        <Button onClick={()=>deleteAllProducts({variables: {input: {deleteAll: true}}})}>{t('clear-list')}</Button>
                    </div>
                    <div>
                        <div style={{fontWeight: "bold"}}>{t('sum')}: {summary_price} PLN</div>
                    </div>
                </div>}
                bordered
                dataSource={data_products}
                renderItem={(item) => (
                    <List.Item style={{display: "flex"}}>
                        <div style={{display: "flex", flexWrap:"wrap", flexDirection: "row"}}>
                            <AvatarPlaceholder category='' file={item.avatar} />
                            <div style={{alignSelf:"center"}}>
                                <Link style={{fontWeight:"bold"}} to={`/product/${item.product_category_id}/${item.product_id}`}>{item.name}</Link><br/>
                                <small>{t('no-cat')}: {item.cat_no}</small>
                            </div>
                        </div>
                        <div>
                            {item.status=='A' && <ArchiveLabelProduct style={{paddingLeft: "1rem", paddingRight: "1rem"}} brand={brand}>{t('archival-product')}</ArchiveLabelProduct> }
                        </div>
                        <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent:"flex-end"}}>
                            <div style={{display: "flex", flexDirection: "row", marginRight:"1rem", flexWrap:"nowrap"}}>
                                <div>{item.count>1 ? <Button onClick={()=>{editCountOrDelete({variables:{input:{delete: false, cart: item.cart_id, count:item.count-1}}})}}>-</Button>: <Button disabled={true}>-</Button>}</div>
                                <div><Input style={{width:`${item.count.toString().length+1}em`, minWidth:"2.5em"}} onChange={(e)=>{editCountOrDelete({variables:{input:{delete: false, cart: item.cart_id, count:e.target.value}}})}} defaultValue={item.count} value={item.count} /></div>
                                <div><Button onClick={()=>{editCountOrDelete({variables:{input:{delete: false, cart: item.cart_id, count:item.count+1}}})}}>+</Button></div>
                            </div>
                            <div>
                            {(item.price!=null && item.no_price==false)&&
                                <div>
                                    <p style={{marginBottom: "0px", padding:"0px", fontWeight:"bold"}}>{item.price*item.count} PLN</p>
                                    <small style={{fontWeight:"bold"}}>{Math.round(item.price*100)/100} PLN / szt.</small>
                                </div>}
                            </div>
                            <div style={{fontSize: "2rem"}}><DeleteOutlined  onClick={()=>{editCountOrDelete({variables:{input:{delete: true, cart: item.cart_id, count:item.count}}})}} title={t("delete")} /></div>
                        </div>
                    </List.Item>
                )}
            />

            <Divider orientation="left">
                {t('my-product-lists')}
            </Divider>
            <List
                header={<div>{t('product-lists')}</div>}
                footer={<div>
                </div>}
                bordered
                dataSource={cart_lists}
                renderItem={(item) => (
                    <List.Item style={{display: "flex", flexWrap: "wrap"}}>
                        <div style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "stretch", flexWrap: "wrap"}}>
                            <div>{item.name}</div>
                            <div>{t('price')}: {item.summary_price? `${Math.round(item.summary_price*100)/100} PLN`: 'Brak'}</div>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", fontSize:"2rem"}}>
                            <div style={{margin:".5rem"}}><InfoCircleOutlined title={t('products-preview')} onClick={()=>{ getCartListProduct({cart_id:item.id, fetchPolicy: "no-cache"});showPreviewModal(true)}} /></div>
                            <div style={{margin:".5rem"}}><DeleteOutlined title={t("delete")} onClick={()=>deleteCartConfirmShow(item.id)} /></div>
                            <div style={{margin:".5rem"}}><ShoppingCartOutlined title={t('add-to-cart')} onClick={()=>manageCartList({variables:{input:{listId:item.id, restoreToCart: true, generateFile: false, delete: false}}})} /></div>
                            <div style={{margin:".5rem"}}><DownloadOutlined onClick={()=>download_file(item.id, item.name)} title={t('download-file')} /></div>
                        </div>
                    </List.Item>
                )}
            />
        </div>
    )
}

export default withApollo(ProductsCartContainer);