import gql from 'graphql-tag';

const CREATE_USER_COMMENT = gql`
    mutation($input: CreateUserCommentMutationInput!){
        createUserCommentMutation(input: $input){
            comment{
                id,
                createDate,
                comment
            }
        }
    }
`
export default CREATE_USER_COMMENT;