import React, {useState, useEffect, useContext} from 'react';
import {withApollo, useMutation} from "react-apollo";
import {PromoDescription, StyledListItem, StyledPrice, StyledPromoPrice} from "../_styledComponents";
import {DeleteFilled} from "@ant-design/icons";
import {Button, Card, Comment, Form, List, Input, Tooltip, message, Divider, Tag} from 'antd';
import CREATE_USER_COMMENT from "../../gql/mutations/CREATE_USER_COMMENT";
import DELETE_USER_COMMENT from "../../gql/mutations/DELETE_USER_COMMENT";
import GET_ALL_FAVOURITE_PRODUCTS from '../../gql/queries/GET_ALL_FAVOURITE_PRODUCTS'
import EDIT_USER_COMMENT from "../../gql/mutations/EDIT_USER_COMMENT";
import FavouriteProductSelector from '../FavouriteProductSelector';
import {Link} from 'react-router-dom';
import AvatarPlaceholder from "../AvatarPlaceholder";
import styled from "styled-components";
import ThemeContext from "../../context/themeContext/themeContext";
import Cookies from 'universal-cookie';
import {ArchiveLabelProduct} from "../_styledComponents";
import {withTranslation} from "react-i18next";
import { useTranslation } from 'react-i18next';

const NoPriceComment = styled.span`
  opacity: 0.6;
  font-weight: 600;
  max-width: calc(100% - 65px);
`;

const { TextArea } = Input;

const CommentTemplate = (props)=>{
    const { t, i18n } = useTranslation();
    const [editCommentStatus, editCommentHandler] = useState(false)
    const [editCommentMutation, {data, loading, error}] = useMutation(EDIT_USER_COMMENT)
    const [editCommentContent, editCommentContentHandler] = useState(props.item.content_raw)
    const actions = [props.item.actions,
        <span style={{paddingLeft:"8px", cursor:"auto"}} onClick={()=>{editCommentHandler(true)}}>Edytuj</span>
    ]
    useEffect(()=>{
        if(data){
            editCommentHandler(false)
            message.success("Komentarz Edytowano")
        }
    }, [data])
    if(editCommentStatus===false){
        return(
            <Comment
                style={{whiteSpace:"pre-wrap"}}
                actions={actions}
                author={props.item.author}
                content={editCommentContent}
                datetime={props.item.datetime}
            />
        )
    }else{
        return(
            <>
            <TextArea onChange={(e)=>{
                editCommentContentHandler(e.target.value)
            }} rows={4} defaultValue={editCommentContent} />
            <Button type="danger" onClick={()=> { editCommentContentHandler(props.item.content_raw); editCommentHandler(false);}}>Anuluj</Button>
                <Button onClick={()=> {editCommentMutation({variables:{input:{"commentId": props.item.id, "commentContent": editCommentContent}}})}}>Zapisz</Button>
            </>
        )
    }
}

const FavouriteProductTemplate = ({client, product, forceUpdateFavourites, favouriteGroups}) =>{
    const { t, i18n } = useTranslation('favouriteProducts');
    const {brand} = useContext(ThemeContext);
    const [newCommentValue, setNewComment] = useState("")
    const [addCommentMutation, {data: data_comment, loading: loading_comment, error: error_comment}] = useMutation(CREATE_USER_COMMENT)
    const [deleteCommentMutation, {data: data_del_comment, loading: loading_del_comment, error: loading_del_error}] = useMutation(DELETE_USER_COMMENT)
    const avatarFile = product.node.product.attachments.edges.map(f => f);
    const price = product.node.product.currentPrice;
    const previousPrice = product.node.product.previousPrice;
    const previousPriceModified = previousPrice !== null ? new Number(previousPrice.value).toLocaleString('pl-PL') : null;
    const modifiedPrice = price ? new Number(price.value).toLocaleString('pl-PL') : null;
    const currentIsPromo = price !== null ? price.promotion : null
    let comments = []
    useEffect(()=>{
            if(data_comment){
                message.success(t('comment-added'))
                forceUpdateFavourites()
            }
        }
    ,[data_comment])
    useEffect(()=>{
        if(data_del_comment){
            message.success(t('comment-deleted'))
            forceUpdateFavourites()
        }
    }, [data_del_comment])
    if(product.node.product.usercommentsSet.edges.length>0){
        product.node.product.usercommentsSet.edges.map(
            (comment)=>{
                comments.push(
                    {
                        id: comment.node.id,
                        content_raw: comment.node.comment,
                        actions: [<span style={{paddingLeft:"8px", cursor:"auto"}} onClick={()=>deleteCommentMutation({
                            variables:{
                                input:{
                                    "commentId": comment.node.id
                                }
                            }
                        })}>{t('delete')}</span>,],
                        author:"",
                        content: (
                            <p>
                                {comment.node.comment}
                            </p>
                        ),
                        datetime: (
                            <Tooltip title={comment.node.createDate}>
                                <span>{comment.node.createDate}</span>
                            </Tooltip>
                        ),
                    }
                )
            }
        )
    }
    let brandColor = brand=="V" ? "#00917e": "#cc0033";
    return(
        <div style={{display: "flex", flexDirection: "column", justifyItems:"center", flexWrap:'wrap'}}>
            <StyledListItem style={{width:"100%", justifyContent:"center"}}>
                <Card style={{width:'80%',marginTop: 16}} title={<><Link to={`/product/${product.node.product.category.id}/${product.node.product.id}`}>{product.node.product.name}</Link>{product.node.product.status=='A' && <ArchiveLabelProduct brand={brand}>{t('archival-product')}</ArchiveLabelProduct>}</>} extra={<FavouriteProductSelector selectedFavouriteGroups={product.node.favouritesgroupsSet.edges} favouriteGroups={favouriteGroups} forceUpdateFavourites={forceUpdateFavourites} key={product.node.product.id} productId={product.node.product.id} favouriteStatus={true}/>}>
                    <div>
                    {t('no-cat')}: {product.node.product.catalogueNumber}<br />
                        <div style={{display: "flex", flexDirection:"row", flexWrap:"wrap", alignItems:"flex-start"}}><p style={{ margin:0}}>{t('groups')}:</p> {product.node.favouritesgroupsSet.edges.map(
                        (group) => <Tag color={brandColor}  style={{overflow:"clip", margin:"1px"}} key={group.node.id}>{group.node.name}</Tag>
                    )}</div>
                    </div>
                    <br />
                    <hr />
                    <div style={{display: "flex", flexDirection: "row", justifyItems:"center", flexWrap:'wrap'}}>
                        <div>
                            <AvatarPlaceholder category={product.node.product.category.id} file={avatarFile} />
                        </div>
                        <div>
                            <b>{t('short-description')}:</b>
                            <p>{product.node.product.descriptionShort}</p>
                            <div style={{position: 'relative', marginTop: '30px', height: '50px', }}>
                                <div style={{display: 'flex', alignItems: 'center', marginRight: '50px'}}>
                                    {(product.node.product.noPrice || !price)
                                        ?
                                        <NoPriceComment>{product.node.product.noPriceComment}</NoPriceComment>

                                        : previousPrice !== null && currentIsPromo  ?
                                            <StyledPromoPrice themeColor={({theme}) => theme[brand].primaryColor}>
                                                {`${previousPriceModified} PLN netto`}
                                                <span className={'promo-line'} themeColor={({theme}) => theme[brand].promoLabel}></span>
                                            </StyledPromoPrice> :
                                            <StyledPrice colorSet={({theme}) => theme.color.primaryColor} style={{textAlign: 'left', display: 'inline'}}>
                                                {`${modifiedPrice} PLN netto`}
                                            </StyledPrice>
                                    }
                                    {
                                        previousPrice !== null && currentIsPromo ?
                                            <PromoDescription className={'promo'}>
                                                <span className={'price-bold'}>{modifiedPrice}</span><span>PLN netto</span>
                                            </PromoDescription>
                                            : null
                                    }
                                </div>
                            </div>
                            <div style={{position: 'relative'}}>
                                {previousPrice !== null && currentIsPromo ?
                                    <PromoDescription className={'promo product-list'}>
                                        <span className={'bold-price'}>{modifiedPrice}</span> <span> PLN netto</span>
                                    </PromoDescription> : null

                                }
                            </div>
                        </div>
                        <div style={{"width": "100%"}}>
                            <List
                                className="comment-list"
                                style={{"width": "100%"}}
                                header={`${comments.length} ${t('comments')}`}
                                itemLayout="horizontal"
                                dataSource={comments}
                                renderItem={item => (
                                    <li>
                                        <CommentTemplate item={item} />
                                    </li>
                                )}
                            />
                            <Comment
                                content={
                                    <>
                                        <Form.Item>
                                            <TextArea onChange={(e)=>setNewComment(e.target.value)} value={newCommentValue} rows={4} placeholder={t('add-comment')}/>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button onClick={()=>addCommentMutation(
                                                {variables:{
                                                    input: {
                                                        "commentValue": newCommentValue,
                                                        "product": product.node.product.id
                                                    }
                                                    }},
                                                    setNewComment("")
                                            )} htmlType="submit" type="primary">
                                                {t('add-comment')}
                                            </Button>
                                        </Form.Item>
                                    </>
                                }
                            />
                        </div>
                    </div>
                </Card>
            </StyledListItem>
        </div>
    )}

class FavouriteProductsList extends React.Component{
    constructor(props) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            products_list: [],
            all_products:[],
            brand: cookies.get('selectedBrand')
        }
    }
    componentDidMount() {
        this.getAllProducts()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!==this.props){
           this.filterProducts()
        }
    }

    forceUpdateFavourites = () => {
        this.setState({products_list:[], all_products:[]})
        this.getAllProducts()
        this.filterProducts()
    }

    getAllProducts = () =>{
        const {client} = this.props
        client.query({
            query: GET_ALL_FAVOURITE_PRODUCTS, variables:{brand: this.state.brand},
            fetchPolicy: "no-cache"
        }).then(
            res=>{
                this.setState({all_products: res.data.general.favouriteProducts.edges})
                this.filterProducts()
            }
        )

    }
    filterProducts = () =>{
        if(this.props.selectedGroups.length>0) {
            let tmp_product_list = []
            this.state.all_products.map((favouriteProduct) => {
                let add = false
                favouriteProduct.node.favouritesgroupsSet.edges.map(
                    (product_group) => {
                        if (this.props.selectedGroups.includes(product_group.node.id)) {
                            add = true
                        }
                    }
                )
                if (add === true)
                    tmp_product_list.push(favouriteProduct)
            })
            this.setState({products_list: tmp_product_list})
        }else{
            this.setState({products_list: this.state.all_products})
        }
    }

    render(){
        return(
            <div>
                <ul>
                {this.state.products_list.map(
                    (product)=> <FavouriteProductTemplate favouriteGroups={this.props.favouriteGroups} forceUpdateFavourites={this.forceUpdateFavourites} key={product.id} product={product} />
                )}
                </ul>
            </div>
        )
    }
}

export default withApollo(withTranslation('favouriteProducts')(FavouriteProductsList))