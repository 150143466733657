import gql from 'graphql-tag';

const GET_SUBCATEGORIES = gql`
    query getSubCategories($parent: ID!) {
        general {
            categories(parent: $parent) {
                edges {
                    node {
                        id
                        name
                        childrenProductCount
                        image
                        isLeaf
                        displayName
                        publishedProductsCumulativeCount
                        childrenWithPublishedProducts {
                            totalCount
                        }
                        pathToRoot {
                            id
                            name
                            displayName
                        }
                        products {
                            totalCount
                        }
                        children {
                            totalCount
                        }
                        archivedProductsCumulativeCount
                    }
                }
            }
        }
    }
`;

export default GET_SUBCATEGORIES;
