import React from "react";
import StyledTileGridLayout from "../../../components/_styledComponents/StyledTileGridLayout";
import { useQuery } from "@apollo/react-hooks";
import GET_SUBCATEGORIES from "../../../gql/queries/GET_SUBCATEGORIES";
import ConstructTile from "../../../components/ConstructTile";
import CategoryBreadcrumb from "../../../components/CategoryBreadcrumb/CategoryBreadcrumb";
import CenteredSpin from "../../../components/_styledComponents/CenteredSpin/CenteredSpin";
import styled from "styled-components";

const StyledHeader = styled.div`
  margin: 2rem auto 0.5rem;
  max-width: ${({ theme }) => theme.layout.containerWidth};
  grid-column: 1/-1;
  position: relative;
  width: 100%;
  padding: 0 1rem;
`;

const ProductsGrid = ({ match }) => {
  const { category_id } = match.params;
  const { data, loading } = useQuery(
    GET_SUBCATEGORIES,
    { variables: { parent: category_id } }
  );

  if (loading) return <CenteredSpin width="100vw" height="60vh" />;
  const { edges: categories } = data.general.categories;

  return (
    <StyledTileGridLayout>
      <StyledHeader>
        <CategoryBreadcrumb categories={categories} />
      </StyledHeader>
      <ConstructTile categories={categories} />
    </StyledTileGridLayout>
  );
};

export default ProductsGrid;
