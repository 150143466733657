import React, {useEffect, useContext, useState} from "react";
import {Query, useMutation} from "react-apollo";
import {useQuery} from "@apollo/react-hooks"
import ADD_TO_FAVOURITE_PRODUCTS from "../../gql/mutations/ADD_TO_FAVOURITES_PRODUCTS";
import DELETE_FROM_FAVOURITE_PRODUCTS from "../../gql/mutations/DELETE_FROM_FAVOURITE_PRODUCTS";
import GET_ALL_FAVOURITE_GROUPS from "../../gql/queries/GET_ALL_FAVOURITE_GROUPS";
import {Checkbox, Dropdown, Menu, message, Button} from "antd";
import {HeartFilled, HeartOutlined, ShoppingCartOutlined} from "@ant-design/icons";
import UPDATE_FAVOURITE_GROUPS_WITH_PRODUCT from "../../gql/mutations/UPDATE_FAVOURITE_GROUPS_WITH_PRODUCT";
import ADD_PRODUCT_TO_CART from "../../gql/mutations/ADD_PRODUCT_TO_CART";
import {useTranslation, withTranslation} from "react-i18next";

const FavouriteProductSelector = (props) =>{
    const { t, i18n } = useTranslation('favouriteProducts');
    const [addToFavourite, {data: add_data, loading: add_loading, error: add_error}] = useMutation(ADD_TO_FAVOURITE_PRODUCTS)
    const [deleteFromFavourite, {data: del_data, loading: del_loading, error: del_error}] = useMutation(DELETE_FROM_FAVOURITE_PRODUCTS);
    const [updateFavouriteGroupsWithProduct, {data: update_data, loading: update_loading, error: update_error}] = useMutation(UPDATE_FAVOURITE_GROUPS_WITH_PRODUCT)
    const [is_favourite, setAsFavourite] = useState(props.favouriteStatus)
    const [addToCartMutation, {data:cart_data, loading:cart_loading, error:cart_error}] = useMutation(ADD_PRODUCT_TO_CART)

    useEffect(()=>{
        try{
            if(cart_data.addProductToCart.added){
                message.success(t("product-have-been-added-to-the-cart"))
            }
        }catch (e) {
            if(e instanceof TypeError){
            }else{
                throw e;
            }
        }
    })
    useEffect(()=> {
        try {
            if (props.productId == add_data.createFavouriteProductMutation.product.id) {
                setAsFavourite(true)
                message.success(t("added-to-favorite-products"))
                try{
                    props.forceUpdateFavourites()
                }catch (e) {
                    
                }
            }
        } catch (e) {
        }
    }, [add_data])
    useEffect(()=>{
        if(update_data){
            message.success(t("product-group-changed"))
            try{
                props.forceUpdateFavourites()
            }catch(e){

            }
        }
    }, [update_data])
    const selectedFavouriteGroups = props.selectedFavouriteGroups.map(
        (group) =>{
            return group.node.id
        }
    )
    useEffect(() =>{
        try {
            if (props.productId == del_data.deleteFavouriteProductMutation.product.id) {
                message.error(t("removed-from-favorite-products"))
                setAsFavourite(false)
                try{
                    props.forceUpdateFavourites()
                }catch (e) {
                }
            }
        } catch (e) {
        }
    }, [del_data])
    return(
        <div>
            {is_favourite?
            <Dropdown.Button trigger={['click', "hover"]} overlay={
                <Checkbox.Group defaultValue={selectedFavouriteGroups} onChange={(e)=> updateFavouriteGroupsWithProduct({variables:{input:{productId:props.productId, groupsId:JSON.stringify(e)}}})} style={{background: "white", padding:"5px"}}>
                    {props.favouriteGroups.length == 0 &&
                        <><div style={{border: "1px solid #a69C9C", borderRadius:"5px", padding: "10px", margin: "2px", width:"100%"}} key={1}>{t("no-groups-to-choose-from-to-create-groups-of-favorite-products-go-to-group-management")}</div><br/>
                        </>
                    }
                    {props.favouriteGroups.map((group) =>{
                            return(<><Checkbox style={{border: "1px solid #a69C9C", borderRadius:"5px", padding: "10px", margin: "2px", width:"100%"}} key={group.node.id} value={group.node.id}>{group.node.name}</Checkbox><br/></>)
                        }
                    )}
                </Checkbox.Group>}
             arrow={true}
             destroyPopupOnHide={true} onClick={() => {deleteFromFavourite({variables: {input:{product: props.productId}}})}}>
                <HeartFilled/>
            </Dropdown.Button>: <Button
                             arrow={true}
                             destroyPopupOnHide={true}
                             onClick={ () => {addToFavourite({variables: {input:{product: props.productId}}})}}>
                <HeartOutlined/>
            </Button>
            }
            <Button onClick={()=>{addToCartMutation({variables:{input: {"product": props.productId}}})}}><ShoppingCartOutlined/></Button>
        </div>
    )
}

export default  FavouriteProductSelector;