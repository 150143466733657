export default {
    "products-in-cart": "Products in cart",
    "save-as-list": "Save as list",
    "generate-file":"Generate file",
    "clear-list":"Clear list",
    "archival-product": "Archival product",
    "delete": "Delete",
    "my-product-lists": "My product lists",
    "product-lists": "Product lists",
    "products-preview": "Products preview",
    "add-to-cart": "Add to cart",
    "download-file": "Download file",
    "delete-the-shopping-cart": "Delete the shopping cart",
    "are-you-sure-you-want-to-delete-the-saved-basket-of-products": "Are you sure you want to delete the saved basket of products?",
    "basket-cleared": "Basket cleared",
    "products-have-been-added-to-the-cart": "Products have been added to the cart",
    "product-list-removed": "Product list removed",
    "a-request-to-generate-a-file-has-been-sent": "A request to generate a file has been sent",
    "the-number-of-products-has-been-changed": "The number of products has been changed",
    "saved-list": "Saved list",
    "could-not-save-list-for-empty-cart":"Could not save list for empty cart",
    "a-list-with-this-name-already-exists":"A list with this name already exists",
    "save-products-as-a-list":"Save products as a list",
    "products-saved-on-the-list": "Products saved on the list",
    "products-cart": "Products Cart",
    "price": "Price",
    "no-cat": "No cat",
    "sum": "Sum"
}