export default {
    "group-management": "Group Management",
    'favorite-products': "Favourite products",
    "select-the-groups-to-display":"Select the groups to display",
    "group-deleted":"Group deleted",
    "new-group-created":"New group created",
    "group-name-changed":"Group name changed",
    "comment-added":"Comment added",
    "comment-deleted":"Comment deleted",
    "delete": "Delete",
    "no-cat": "No cat",
    "groups": "Groups",
    "short-description": "Short description",
    "counter-comments": "comments",
    "add-comment": "Add comment",
    "archival-product": "Archival Product",
    "product-have-been-added-to-the-cart":"Product have been added to the cart",
    "added-to-favorite-products":"Added to favorite products",
    "product-group-changed":"Product group changed",
    "removed-from-favorite-products":"Removed from favorite products",
    "no-groups-to-choose-from-to-create-groups-of-favorite-products-go-to-group-management":"No groups to choose from - to create groups of favorite products, go to group management",
    "add-a-new-group":"Add a new group",
    "edit-group": "Edit group",
}

// {this.props.t('')}
// {this.props.t('favorite-products')}
// this.props.t('')