import auth from './auth';
import dashboard from './dashboard';
import dataExport from './dataExport';
import productsListContainer from './productsListContainer';
import brandSelect from './brandSelect';
import productGeneralInfo from "./productGeneralInfo";
import navigation from "./navigation";
import profile from "./profile";
import search from "./search";
import passwordReset from "./passwordReset";
import productView from "./productView";
import termsOfService from "./termsOfService";
import promotions from './promotions';
import product_cart_container from './product_cart_container'
import favouriteProducts from './favouriteProducts'

export default {
  auth,
  dashboard,
  dataExport,
  productsListContainer,
  brandSelect,
  productGeneralInfo,
  navigation,
  profile,
  search,
  passwordReset,
  productView,
  termsOfService,
  promotions,
  product_cart_container,
  favouriteProducts
};
