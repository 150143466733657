import React, {useState, useContext} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Header from "./_components/Header";
import Navigation from "./_components/Navigation";
import Search from './_components/Search';
import Footer from "./_components/Footer";
import {Button, Icon, Layout} from 'antd';
import {StyledDrawer} from "../../components/_styledComponents";
import MenuContext from "../../context/menu/MenuContext";
import WindowWidthContext from "../../context/windowWidth/WindowWidthContext";
import Cookies from 'universal-cookie';
import {withRouter} from 'react-router';
import routes from "../../config/routes";

const {Content} = Layout;

const StyledLayout = styled(Layout)`
  display: flex;
  flex-direction: column;
  min-height: 100vh !important;
  overflow-y: hidden;

  &.ant-layout.ant-layout-has-sider {
    flex-direction: column !important;
  }

`;

const StyledContent = styled(Content)`
  background: #fff;
  padding-top: ${({theme}) => theme.layout.headerHeight};
  margin: 0;
  position: relative;
  flex-grow: 1;
  width: auto;
  // 83px => footer
`;

const FixedButton = styled(Button)`
  position: fixed !important;
  bottom: 10px;
  left: 10px;
  border: 3px solid ${({theme}) => theme.color.primaryColor} !important;
  height: 45px !important;
  width: 45px !important;
  border-radius: 50% !important;
  color: ${({theme}) => theme.color.primaryColor} !important;
  font-size: 26px !important;
  padding: 0 !important;
  z-index: 1000;
`;

const StyledContentWrapper = styled.div`
  padding-left: ${({ fixedDrawer }) => fixedDrawer ? '390px' : 0};
`;

const pathsWithHiddenArrow = [ routes.DASHBOARD, routes.BRAND_SELECT, routes.PRODUCTS_GRID ]
const pathsWithFixedDrawer = [ routes.DASHBOARD, routes.PRODUCT, routes.PRODUCTS_LIST ]

const displayArrow = pathname => !pathsWithHiddenArrow.includes(pathname)
const fixedDrawer = pathname => {
  const route = pathname.split('/')[ 1 ] || [];
  return pathsWithFixedDrawer.includes(`/${route}`);
}

const GeneralLayout = ({children, history}) => {
  const [ menu, setMenu ] = useState(false);
  const [ search, setSearch ] = useState(false);
  const {setOpenKeys} = useContext(MenuContext);
  const {windowWidth, windowExactWidth} = useContext(WindowWidthContext);


  const cookies = new Cookies();
  const brand = cookies.get('selectedBrand');

  const toggleMenu = state => {
    if (typeof state === 'boolean') {
      setMenu(state);
      if (!state) setOpenKeys([]);
    } else {
      setSearch(false);
      if (menu) setOpenKeys([]);
      setMenu(!menu);
    }
  };

  const toggleSearch = state => {

    if (typeof state === 'boolean') {
      setSearch(state)
    } else {
      setMenu(false);
      setSearch(!search)
    }
  };

  const drawerConfig = {
    mask: false,
    closable: false,
    getContainer: '#drawerRoot',
    zIndex: 2,
    width: windowExactWidth < 380 ? '100vw' : '380px',
  };

  return (
    <StyledLayout style={{position: 'relative'}}>
      <Header
        menu={menu}
        search={search}
        toggleMenu={toggleMenu}
        toggleSearch={toggleSearch}
        windowWidth={windowWidth}
        hideHamburger={fixedDrawer(history.location.pathname)}
      />
      <Layout style={{maxWidth: '1360px', margin: '0 auto', width: '100%'}}>
        <StyledContent id='drawerRoot'>
          <StyledDrawer
            {...drawerConfig}
            placement="left"
            /* eslint-disable no-mixed-operators */
            visible={windowWidth === 'desktop' && fixedDrawer(history.location.pathname) || menu}
            isOpen={windowWidth === 'desktop' && fixedDrawer(history.location.pathname) || menu}
            /* eslint-enable no-mixed-operators */
          >
            <Navigation drawerVisible={setMenu} windowWidth={windowWidth}/>
          </StyledDrawer>
          <StyledDrawer
            {...drawerConfig}
            placement="right"
            visible={search}
          >
            <Search drawerVisible={setSearch}/>
          </StyledDrawer>
          <StyledContentWrapper fixedDrawer={windowWidth === 'desktop' && fixedDrawer(history.location.pathname) } >
            {children}
          </StyledContentWrapper>
        </StyledContent>
      </Layout>
      {windowWidth === 'mobile' && displayArrow(history.location.pathname) ? <FixedButton
        onClick={history.goBack}
        themeColor={({theme}) => theme[ brand ].primaryColor}>
        <Icon type={'arrow-left'}/>
      </FixedButton> : null
      }
      <Footer/>
    </StyledLayout>
  )
};

GeneralLayout.defaultProps = {};

GeneralLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withRouter(GeneralLayout);
