import gql from 'graphql-tag';

// const GET_CART_LIST = gql`
// query getCartList($brand:String){
//   general{
//     cartList (brand:$brand){
//         id,
//         name,
//         summaryPrice
//     }
//   }
// }
// `

const GET_CART_LIST = gql`
query getCartList($brand:String){
  general {
    cartList(brand:$brand){
      edges{
        node{
          id,
          name,
          summaryPrice
        }
      }
    }
  }
}
`

export default GET_CART_LIST