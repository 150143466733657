import React, {useEffect, useContext, useState} from "react";
import {message, Button} from "antd";
import {useTranslation, withTranslation} from "react-i18next";
import ThemeContext from "../../context/themeContext/themeContext";
import styled from "styled-components";

const ComparsionProductSelector = (props) =>{
    const [is_in_comparison, setComparison] = useState(props.comparedProducts.indexOf(props.productId)>-1)
    const {brand} = useContext(ThemeContext);
    let comparedProducts = props.comparedProducts
    const InComparsionButton = styled(Button)`
         background-color: ${({theme}) => theme.color.primaryColor} !important;
         color: white !important;
     `;
    useEffect(()=>{
        if(is_in_comparison){
            if(!props.comparedProducts.indexOf(props.productId)>-1 && props.comparedProducts.length<5){
                comparedProducts.push(props.productId)
                props.setComparsion(comparedProducts)
                message.success("Dodano produkt do porównania")
            }else if(props.comparedProducts.length>=5){
                message.error("Nie można porównywać więcej niż 5 produktów")
                setComparison(false)
            }

        }else{
            if(props.comparedProducts.indexOf(props.productId)>-1){
                comparedProducts.splice(props.comparedProducts.indexOf(props.productId),1)
                props.setComparsion(comparedProducts)
                message.error("Usunięto produkt z porównania")
            }
        }
    }, [is_in_comparison])
    return(
        <>
            {is_in_comparison?
                <InComparsionButton onClick={()=>{setComparison(!is_in_comparison)}}> W porównaniu</InComparsionButton>:
                <Button onClick={()=>{setComparison(!is_in_comparison)}}>Porównaj</Button>
            }
        </>
    )
}

export default ComparsionProductSelector;