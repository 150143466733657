import gql from 'graphql-tag';

const CREATE_FAVOURITE_GROUP = gql`
    mutation($input: CreateFavouriteProductGroupMutationInput!) {
        createFavouriteProductGroupMutation(input: $input){
            group{
                id,
                name
            }
        }
    }
`

export default CREATE_FAVOURITE_GROUP;