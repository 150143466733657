import React, {useState, useEffect, useContext} from 'react';
import {Button, Select, Tabs, Drawer, message} from 'antd';
import {withApollo} from 'react-apollo';
import GET_ALL_FAVOURITE_GROUPS from "../../gql/queries/GET_ALL_FAVOURITE_GROUPS"
import CREATE_FAVOURITE_GROUP from "../../gql/mutations/CREATE_FAVOURITE_GROUP";
import DELETE_FAVOURITE_GROUP from "../../gql/mutations/DELETE_FAVOURITE_GROUP";
import EDIT_FAVOURITE_PRODUCT_GROUP from "../../gql/mutations/EDIT_FAVOURITE_PRODUCT_GROUP";
import FavouriteProductsList from "../FavouriteProductsList"
import FavouriteGroupsManager from "../FavouriteGroupsManager";
import Cookies from 'universal-cookie';
import {withTranslation} from "react-i18next";

const {Option} = Select;
const {TabPane} = Tabs;
const cookies = new Cookies()

class FavouriteProductsContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            selectedGroups: [],
            groups: [],
            visibilityGroupsManager: false,
        }
    }

    componentDidMount() {
        this.getGroups()
    }

    showGroupsManager = () =>{
        this.setState({visibilityGroupsManager: true})
    }
    hideGroupsManager = () =>{
        this.setState({visibilityGroupsManager: false})
    }

    deleteGroupHandler = (id) =>{
        const {client} = this.props
        message.success(this.props.t('group-deleted'))
        client.mutate({
            mutation: DELETE_FAVOURITE_GROUP, variables: {input:{groupId: id}}
        }).then(
            res=>{
                message.success(this.props.t('group-deleted'))
                this.getGroups()
            }
        )


    }

    addGroupHandler = (name) =>{
        const {client} = this.props
        client.mutate({
            mutation: CREATE_FAVOURITE_GROUP, variables: {input:{groupName: name, brand: cookies.get('selectedBrand')}}
        }).then(
            res=> {
                message.success(this.props.t('new-group-created'))
                this.getGroups()
            }
        )
    }

    editGroupHandler = (id, new_name) =>{
        const {client} = this.props
        client.mutate(
            {
                mutation: EDIT_FAVOURITE_PRODUCT_GROUP, variables: {input:{newGroupName: new_name, groupId: id}}
            }
        ).then(
            res=>{
                message.success(this.props.t('group-name-changed'))
                this.getGroups()
            }
        )
    }

    getGroups = () =>{
        const {client} = this.props
        client.query({
            query: GET_ALL_FAVOURITE_GROUPS,
            fetchPolicy: 'no-cache',
            variables:{brand: cookies.get('selectedBrand')}
        }).then(
            res=>{
                this.setState({groups: res.data.general.favouriteGroups.edges})
            }
        )
    }

    handleChangeSelectedGroups = (selected) =>{
        this.setState({selectedGroups:selected})
    }

    render(){
        return(
            <>
                <Drawer visible={this.state.visibilityGroupsManager} onClose={this.hideGroupsManager} placement="right" title={this.props.t("group-management")}>
                    <FavouriteGroupsManager groups={this.state.groups} editGroupHandler={this.editGroupHandler} deleteGroupHandler={this.deleteGroupHandler} addGroupHandler={this.addGroupHandler} />
                </Drawer>
                <div style={{display: "flex", flexDirection:"column"}}>
                    <p style={{textAlign: 'center', fontSize: '1.5rem', marginBottom: '5px'}}>
                        {this.props.t('favorite-products')}
                    </p>
                    <div style={{display:"flex", flexWrap:"wrap"}}>
                        <div style={{minWidth:"50%"}}>
                        <Select style={{width:"100%"}} mode="multiple" filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        } placeholder={this.props.t('select-the-groups-to-display')}
                                onChange={(selected) => this.handleChangeSelectedGroups(selected)}
                        >
                            {this.state.groups.map(
                                (group)=>
                                    <Option key={group.node.id} value={group.node.id}>{group.node.name}</Option>
                            )}
                        </Select>
                        </div>
                        <Button onClick={this.showGroupsManager} style={{width:"fit-content"}}>{this.props.t("group-management")}</Button><br/>
                    </div>
                    <FavouriteProductsList  selectedGroups={this.state.selectedGroups} favouriteGroups={this.state.groups} />
                </div>
            </>
        )
    }
}

export default withApollo(withTranslation('favouriteProducts')(FavouriteProductsContainer));