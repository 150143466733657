export default {
    "group-management": "Zarządzanie grupami",
    'favorite-products': "Produkty ulubione",
    "select-the-groups-to-display":"Wybierz grupy do wyświetlenia",
    "group-deleted":"Grupa usunięta",
    "new-group-created":"Nowa grupa utworzona",
    "group-name-changed":"Nazwa grupy zmieniona",
    "comment-added":"Dodano komentarz",
    "comment-deleted":"Usunięto komentarz",
    "delete": "Usuń",
    "no-cat": "Nr kat",
    "groups": "Grupy",
    "short-description": "Krótki opis",
    "counter-comments": "komentarzy",
    "add-comment": "Dodaj komentarz",
    "archival-product": "Produkt Archiwalny",
    "product-have-been-added-to-the-cart":"Dodano do koszyka produktów",
    "added-to-favorite-products":"Dodano do ulubionych produktów",
    "product-group-changed":"Zmieniono grupy produktu",
    "removed-from-favorite-products":"Usunięto z ulubionych produktów",
    "no-groups-to-choose-from-to-create-groups-of-favorite-products-go-to-group-management":"Brak grup do wyboru - aby utworzyć grupy ulubionych produktów przejdź do zarządzania grupami",
    "add-a-new-group":"Dodaj nową grupę",
    "edit-group": "Edycja grupy",
}

// {this.props.t('')}
// {this.props.t('select-the-groups-to-display')}
// {this.props.t('favorite-products')}
// this.props.t('')
// this.props.t('group-name-changed')
// this.props.t('new-group-created')
// this.props.t('group-deleted')

// {t('')}
// {t('delete')}
// t('')
// t('')
// t('comment-deleted')
// t('comment-added')