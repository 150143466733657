import React, {useState, useEffect, useContext} from "react";
import {Menu, Icon, Divider} from "antd";
import client from "../../config/apollo/client";
import GET_SUBCATEGORIES from "../../gql/queries/GET_SUBCATEGORIES";
import styled from "styled-components";
import routes from "../../config/routes";
import PropTypes from "prop-types";
import CenteredSpin from "../_styledComponents/CenteredSpin/CenteredSpin";
import MenuContext from "../../context/menu/MenuContext";
import {StyledMenuLink} from "../_styledComponents";

const {SubMenu, Item} = Menu;

const StyledSpan = styled.span`
  color: rgba(0, 0, 0, 0.65) !important;
  font-size: 1.25rem;
  ${({theme}) => theme.mq.big_desktop`
      font-size: 14px;
    `}
`;

const StyledDiv = styled.div`
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);

  i {
    ${({theme}) => theme.mq.big_desktop`
      font-size: 10px !important;
    `}
  }
`;

const StyledDivider = styled(Divider)`
  height: 2em !important;
  background: rgba(0, 0, 0, 0.2) !important;
  width: 1.5px !important;
`;

const CategoryMenu = ({
                        category,
                        handleSelection,
                        windowWidth,
                        isArchive,
                        ...props
                      }) => {
  const [ subcategories, setSubcategories ] = useState([]);
  const {
    openKeys,
    setOpenKeys,
    selectedItem,
    openKeysLevel,
    setOpenKeysLevel,
  } = useContext(MenuContext);

  useEffect(() => {
    if (openKeys.indexOf(category.id) !== -1 && !subcategories.length) {
      getChildren();
    }
  }, []);

  const getChildren = () => {
    client
      .query({
        query: GET_SUBCATEGORIES,
        variables: {
          parent: category.id,
        },
      })
      .then(({data}) => {
        const subcategories = data.general.categories.edges.map(({node}) => node);
        const archivedFilteredCategoriesFunc = ({archivedProductsCumulativeCount}) => archivedProductsCumulativeCount !== 0
        const subcategoriesWithProductsFunc = ({childrenWithPublishedProducts, isLeaf, products}) => {
          const totalCount = isLeaf ? products.totalCount : childrenWithPublishedProducts && childrenWithPublishedProducts.totalCount
          return totalCount > 0
        }
        setSubcategories(
          subcategories.filter(isArchive ? archivedFilteredCategoriesFunc : subcategoriesWithProductsFunc)
        );
      });
  };

  const handleCategoryExpand = () => {
    let keys = openKeys;
    const index = keys.indexOf(category.id);
    if (index > 0) {
      if (isArchive) {
        const currentArray = openKeysLevel.find(
          (obj) => obj.id === category.id
        );
        const openKeysArray = openKeysLevel;
        openKeysArray.forEach((key, i) => {
          if (key.id === currentArray.id) {
            const index = keys.indexOf(currentArray.id);
            openKeysArray.splice(i, 1);
            keys.splice(index, 1);
          }
        });
        setOpenKeys(keys);
        setOpenKeysLevel(openKeysArray);
      } else {
        keys.splice(index, 1);
      }
      if (!keys.length) {
        setOpenKeysLevel([]);
      }
    } else {
      getChildren();
      if (isArchive) {
        const object = {
          id: category.id,
          level: props.level,
        };
        if (openKeysLevel.length) {
          const openKeysArray = openKeysLevel;
          //find items with same level and other nested
          openKeysArray.forEach((key, i) => {
            if (key.level === object.level || key.level > object.level) {
              openKeysArray.splice(i);
              const index = keys.indexOf(key.id);
              keys.splice(index);
            }
          });

          setOpenKeys(keys);
          setOpenKeysLevel(openKeysArray);
        }
        //push item to state
        setOpenKeysLevel(() => [ ...openKeysLevel, object ]);
      }
      keys.push(category.id);
    }
    setOpenKeys(keys);
  };

  const customExpandIcon = () => {
    const isOpen = openKeys.indexOf(category.id) >= 0;

    return (
      <StyledDiv>
        {windowWidth === "mobile" ? <StyledDivider type="vertical"/> : null}
        <Icon
          onClick={(e) => handleCategoryExpand(e)}
          type={isOpen ? "down" : "right"}
        />
      </StyledDiv>
    );
  };
  if (category && category.children && category.children.totalCount > 0) {
    return (
      <SubMenu
        title={
          <StyledMenuLink
            onClick={() => handleSelection(category.id, isArchive)}
            to={`${routes.PRODUCTS_LIST}/${category.id}`}
          >
            <StyledSpan>{category.displayName || category.name}</StyledSpan>
          </StyledMenuLink>
        }
        className={`${selectedItem === category.id ? "selected-item" : ""}`}
        {...props}
        expandIcon={() => customExpandIcon()}
      >
        {subcategories.length ? (
          subcategories.map((subcategory) => {
            return (
              <CategoryMenu
                category={subcategory}
                key={subcategory.id}
                handleSelection={handleSelection}
                isArchive={isArchive}
              />
            );
          })
        ) : (
          <CenteredSpin/>
        )}
      </SubMenu>
    );
  }
  return (
    <Item
      key={category.id}
      {...props}
      className={`${selectedItem === category.id ? "selected-item" : ""}`}
    >
      <StyledMenuLink
        to={`${routes.PRODUCTS_LIST}/${category.id}`}
        onClick={() => handleSelection(category.id, isArchive)}
      >
        <StyledSpan>{category.displayName || category.name}</StyledSpan>
      </StyledMenuLink>
    </Item>
  );
};

CategoryMenu.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    logo: PropTypes.string,
    children: PropTypes.shape({
      totalCount: PropTypes.number,
    }),
  }),
  openKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  setOpenKeys: PropTypes.func.isRequired,
  handleSelection: PropTypes.func.isRequired,
  selectedItem: PropTypes.string.isRequired,
};

export default CategoryMenu;
