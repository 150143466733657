import React, {useContext, useEffect} from 'react';
import {Query, useQuery} from "react-apollo";
import PRODUCT_GENERAL_INFO from "../../gql/queries/PRODUCT_GENERAL_INFO";
import {Carousel, Icon, Popover} from "antd";
import {
  StyledLink,
  StyledTitle,
  FlexContainer,
  ProductBasicInfoCont,
  StyledPrice,
  StyledInfoContainer,
  StyledPromoPrice, PromoDescription, ArchiveLabelProduct
} from "../_styledComponents";
import {withTranslation} from "react-i18next";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CenteredSpin from "../_styledComponents/CenteredSpin/CenteredSpin";
import WindowWidthContext from "../../context/windowWidth/WindowWidthContext";
import ThemeContext from "../../context/themeContext/themeContext";
import ArchiveContext from "../../context/archive/ArchiveContext";
import MenuContext from "../../context/menu/MenuContext";
import FavouriteProductSelector from "../FavouriteProductSelector";
import GET_ALL_FAVOURITE_GROUPS from "../../gql/queries/GET_ALL_FAVOURITE_GROUPS";
import {InfoCircleOutlined} from "@ant-design/icons";

const StyledCarousel = styled(Carousel)`
  max-height: 230px;
  overflow: hidden;
  
  img {
    object-fit: contain;
    max-height: 230px;
  }
  
  img {
     ${({theme}) => theme.mq.big_desktop`
        margin: 0;
  `}
	  
  }
`;

const CarouselImgWrapper = styled.div`
  display: flex; 
  justify-content: center; 
  align-items: center;
  
  img {
    width: 200px; 
    margin: 10px auto 0;
  }
  
   img {
     ${({theme}) => theme.mq.big_desktop`
        margin: 0 auto;
  `}
	  
  }
`;

const NoPhotoWrapper = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-size: 50px;
    color: #ccc;
  }
`;


const ProductGeneralInfo = ({category_id, id, t}) => {
  const categoryLinkMessage = (category_id.category_id !== 'new' && category_id.category_id !== 'discount');
  const {windowWidth} = useContext(WindowWidthContext);
  const {brand} = useContext(ThemeContext);
  const {openArchive, setOpenArchive} = useContext(ArchiveContext);
  const {data: favouriteGroups} = useQuery(GET_ALL_FAVOURITE_GROUPS, {fetchPolicy: "no-cache", variables: {brand: brand}});
  const {data, loading, error} = useQuery(PRODUCT_GENERAL_INFO, {fetchPolicy: "no-cache",variables: {id: id}})
  if (loading) return <CenteredSpin height={'100vh'}/>;
  if (!data) return null;
  const productInfo = data.general.products.edges[0].node;
  let favouriteGroupsVar
  try{
    favouriteGroupsVar = favouriteGroups.general.favouriteGroups.edges
  }catch (e){
    favouriteGroupsVar = []
  }
  const selectedFavouriteGroups = productInfo.favouritesproductsSet.edges.length>0 ? productInfo.favouritesproductsSet.edges[0].node.favouritesgroupsSet.edges : []
  const productPrice = productInfo.currentPrice;
  const lastLowestPrice = productInfo.lastLowestPrice
  const isCurrentPromo = productPrice !== null ? productPrice.promotion : null;
  const previousPrice = productInfo.previousPrice;
  const modifiedPrice = productPrice ? new Number(productPrice.value).toLocaleString('pl-PL') : null;
  const modifiedPreviousPrice = previousPrice ? new Number(previousPrice.value).toLocaleString('pl-PL') : null;
  const descriptionLong = productInfo.descriptionLong;
  const productStatus = productInfo.status;
  if (productStatus === 'A') setOpenArchive(true);
  const productPhotos = productInfo.attachments.edges.map(({node}, index) => (
    <CarouselImgWrapper key={index}>
      <img src={`${process.env.REACT_APP_URL}${node.file}`} alt='product'/>
    </CarouselImgWrapper>
  ));

  const pricesComponent = () => {
    return (
        <>
        <div style={{position: "relative"}}>
          <div>
             {previousPrice !== null && windowWidth !== 'mobile' && isCurrentPromo ?
                 <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '15px'}}>
                   <StyledPromoPrice themeColor={({theme}) => theme[brand].primaryColor}>
                        {`${modifiedPreviousPrice} PLN netto`}
                        <span className={'promo-line'} themeColor={({theme}) => theme[brand].promoLabel}></span>
                    </StyledPromoPrice>
                   <PromoDescription className={'promo product-view'}>
                      <span className={'bold-price'}>{modifiedPrice} <br/> </span><span>PLN netto</span>
                    </PromoDescription>
                 </div>
                 : previousPrice !== null && windowWidth === 'mobile' && isCurrentPromo ?
                     <div style={{textAlign: 'right'}}>
                         <StyledPromoPrice themeColor={({theme}) => theme[brand].primaryColor} className={windowWidth}>
                            {`${modifiedPreviousPrice} PLN netto`}
                            <span className={'promo-line'} themeColor={({theme}) => theme[brand].promoLabel}></span>
                        </StyledPromoPrice>
                     </div>
                     :

                    <StyledPrice className='product-view-price' themeColor={({theme}) => theme[brand].promoLabel}>
              {(productInfo.noPrice || !modifiedPrice) ? productInfo.noPriceComment : `${modifiedPrice} PLN netto`}
            </StyledPrice>
          }
          </div>
        </div>
        {
          previousPrice !== null && windowWidth !== 'mobile' && isCurrentPromo ?
              <div style={{marginTop:"1em"}}>
                <small>
                  Najniższa ostatnia cena: {lastLowestPrice}PLN
                  <Popover content="Jest to najniższa cena produktu w ciągu ostatnich 30 dni przed zastosowaniem obniżki ceny" trigger="hover">
                    <InfoCircleOutlined style={{marginLeft:".25em", fontSize: "1.5em"}} />
                  </Popover>
                </small>
              </div>
              : null
        }
        {
          previousPrice !== null && windowWidth === 'mobile' && isCurrentPromo ?
              <div style={{marginTop:"1em"}}>
                <small>
                  <p>Najniższa ostatnia cena: {lastLowestPrice}PLN<br/>
                    Jest to najniższa cena produktu w ciągu ostatnich 30 dni przed zastosowaniem obniżki ceny</p>
                </small>
              </div>
              : null
        }
        </>
        )
  }
  const desktopOrder = () => (
    <StyledInfoContainer brand={brand}>
     {productPhotos.length > 0 ?
        <StyledCarousel>{productPhotos}</StyledCarousel>
          :
        <NoPhotoWrapper><Icon type="picture"/></NoPhotoWrapper>
      }
      <ProductBasicInfoCont>
        <div>
          {(openArchive && productInfo.status === 'A')  && <ArchiveLabelProduct brand={brand}>{t('archive')}</ArchiveLabelProduct>}
          <h3>{productInfo.name}</h3>
          <p className='number'>Nr kat.: {productInfo.catalogueNumber}</p>
          {descriptionLong && <p className='description'>{descriptionLong}</p>}
        </div>
        {pricesComponent()}
      </ProductBasicInfoCont>
      <FavouriteProductSelector selectedFavouriteGroups={selectedFavouriteGroups} favouriteGroups={favouriteGroupsVar} productId={productInfo.id} favouriteStatus={productInfo.isInFavourites} />
    </StyledInfoContainer>
  );
  const createPath = category_id === 'discount' ? '/promotions' :  category_id === 'new' ? `/products-list/${productInfo.mainCategory.id}` : `/products-list/${category_id}`;
  return (
    <>
      <StyledTitle className='product-view'>
        <StyledLink to={createPath}>
          <Icon type="left" style={{fontSize: '14px', marginRight: '5px'}}/>
          {categoryLinkMessage ? productInfo.mainCategory.name : t(category_id)}
        </StyledLink>
      </StyledTitle>
      {windowWidth === 'mobile' ? (
        <>
          <StyledInfoContainer>
            <ProductBasicInfoCont>
              {(openArchive && productInfo.status === 'A') && <ArchiveLabelProduct brand={brand}>{t('archive')}</ArchiveLabelProduct>}
              <h3>{productInfo.name}</h3>
              <p className='number'>Nr kat.: {productInfo.catalogueNumber}</p>
            </ProductBasicInfoCont>
            <FavouriteProductSelector selectedFavouriteGroups={selectedFavouriteGroups} favouriteGroups={favouriteGroupsVar} productId={productInfo.id} favouriteStatus={productInfo.isInFavourites} />
            <div style={{position: "relative"}}>
              {previousPrice !== null && isCurrentPromo ?
                    <PromoDescription className={'promo product-view'}>
                      <span className={'bold-price'}>{modifiedPrice} <br/> </span><span>PLN netto</span>
                      </PromoDescription> : null
              }
                {productPhotos.length > 0
                  ?
                <StyledCarousel>{productPhotos}</StyledCarousel>
                  :
                <FlexContainer style={{height: '150px'}}>
                  <Icon type="picture" style={{fontSize: '75px'}}/>
                </FlexContainer>
                  ?
                null
                  :
                <FlexContainer style={{height: '150px'}}>
                  <Icon type="picture" style={{fontSize: '75px'}}/>
                </FlexContainer>
              }
            </div>

          </StyledInfoContainer>
          <div>
            {pricesComponent()}
            {descriptionLong &&
              <p style={{
                width: '90%',
                margin: '0 auto',
                textAlign: 'justify',
                lineHeight: '14px',
                fontSize: '14px',
                padding: '0 0 10px'
              }}>
                {descriptionLong}
              </p>
            }
          </div>
        </>
      ) : desktopOrder()}
    </>
  )
};

ProductGeneralInfo.propTypes = {
  categoryId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

const TranslatedComponent = withTranslation('productGeneralInfo')(ProductGeneralInfo);
export default TranslatedComponent;