import React, {useEffect, useContext, useState} from "react";
import {Modal, Button, message} from 'antd';
import AvatarPlaceholder from "../AvatarPlaceholder"
import {useQuery} from "react-apollo";
import ThemeContext from "../../context/themeContext/themeContext";
import WindowWidthContext from "../../context/windowWidth/WindowWidthContext";
import GET_COMPARSION_PRODUCTS from "../../gql/queries/GET_COMPARSION_PRODUCTS"
import {StyledPrice} from "../_styledComponents";
import Cookie from "universal-cookie";

const ComparsionMobileTable = (props) =>{
    const {brand} = useContext(ThemeContext);
    const [comparsion_products, setComparsionProducts] = useState([])
    const [comparsion_parameters, setComparsionParameters] = useState([])
    useEffect(()=>{
        if(props.products_data){
            let tmp_comparsion_parameters = {}
            let tmp_comparsion_parameters_ids = []
            let tmp_products = {}
            for(const key in props.products_data.general.productsComparsionList.edges){
                let product = props.products_data.general.productsComparsionList.edges[key].node
                tmp_products[product.id]={
                    id: product.id,
                    name: product.name,
                    ean: product.ean,
                    currentPrice: product.currentPrice,
                    attachments: product.attachments,
                    catalogueNumber: product.catalogueNumber,
                    status: product.status,
                    descriptionShort: product.descriptionShort,
                    productparameterSet:{}
                }
                for(const key2 in product.productparameterSet.edges){
                    let product_parameter_set = product.productparameterSet.edges[key2].node
                    let value = ''
                    let unit = product_parameter_set.parameter.unit;
                    switch (product_parameter_set.parameter.type) {
                        case "N":
                            value = product_parameter_set.numberValue;
                            break;
                        case "B":
                            value = product_parameter_set.numberValue === 1 ? `Tak` : `Nie`;
                            break;
                        case "T":
                        case "C":
                            value = product_parameter_set.textValue;
                            break;
                        case "A":
                            value = product_parameter_set.arrayValue ? product_parameter_set.arrayValue.join(', ') : value;
                            break;
                        case "R":
                            value = `${product_parameter_set.numberValue} - ${product_parameter_set.numberValueTo}`;
                            break;

                    }
                    if(unit && value !== ''){
                        value = `${value} ${unit}`
                    }
                    tmp_products[product.id].productparameterSet[product_parameter_set.parameter.id]={
                        id: product_parameter_set.parameter.id,
                        name: product_parameter_set.parameter.name,
                        value: value,
                    }
                    tmp_comparsion_parameters[product_parameter_set.parameter.id] = {
                        id: product_parameter_set.parameter.id,
                        name: product_parameter_set.parameter.name
                    }
                    if(tmp_comparsion_parameters_ids.includes(product_parameter_set.parameter.id)===false){
                        tmp_comparsion_parameters_ids.push(product_parameter_set.parameter.id)
                    }
                }
            }
            props.setParameters(tmp_comparsion_parameters_ids)
            setComparsionParameters(tmp_comparsion_parameters)
            setComparsionProducts(tmp_products)
        }
    },[props.products_data])
    return(
        <div style={{overflow:"scroll"}}>
            <table className="comparsionPreviewTableMobile">
                <thead>
                    <tr>
                    {Object.keys(comparsion_products).map((product)=>
                        <td>
                            <div>
                                <h3>{comparsion_products[product].name}</h3>
                                <p style={{
                                    width: '80%',
                                    fontSize: '14px',
                                    lineHeight: '14px'
                                }}>
                                    {`Nr kat.: ${comparsion_products[product].catalogueNumber}`}
                                </p>
                                <p style={{
                                    width: '80%',
                                    fontSize: '14px',
                                    lineHeight: '14px'
                                }}>
                                    {comparsion_products[product].descriptionShort}
                                </p>
                                {comparsion_products[product].currentPrice &&
                                    <StyledPrice colorSet={({theme}) => theme.color.primaryColor}
                                                 style={{textAlign: 'left'}}>
                                        {`${comparsion_products[product].currentPrice.value} PLN netto`}
                                    </StyledPrice>
                                }
                                <AvatarPlaceholder file={comparsion_products[product].attachments.edges} category={props.categoryId} />
                            </div>
                        </td>)}
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(comparsion_parameters).map(
                        (parameter_id)=>
                            <>
                            <tr className={`parameter_name ${brand}`} key={comparsion_parameters[parameter_id].id}>
                                <td style={{width:"100%", justifyContent:"center"}} colSpan={5} >
                                    <p style={{position: "sticky", textAlign: "center", top:0, left:0, width: "88vw"}}>{comparsion_parameters[parameter_id].name}</p>
                                </td>
                            </tr>
                            <tr>
                                {Object.keys(comparsion_products).map(
                                    (product_id)=>
                                        <td>
                                            {comparsion_products[product_id].productparameterSet[parameter_id]? <>{comparsion_products[product_id].productparameterSet[parameter_id].value}</>: <>-</>}
                                        </td>
                                )}
                            </tr>
                            </>
                    )}
                </tbody>
            </table>
        </div>
    )
}

const ComparsionDesktopTable = (props) =>{
    const {brand} = useContext(ThemeContext);
    const [comparsion_products, setComparsionProducts] = useState([])
    const [comparsion_parameters, setComparsionParameters] = useState([])
    const [columnWidth, setColumnWidth] = useState(16)
    useEffect(()=>{
        if(props.products_data){
            let tmp_comparsion_parameters = {}
            let tmp_comparsion_parameters_ids = []
            let tmp_products = {}
            for(const key in props.products_data.general.productsComparsionList.edges){
                let product = props.products_data.general.productsComparsionList.edges[key].node
                tmp_products[product.id]={
                    id: product.id,
                    name: product.name,
                    ean: product.ean,
                    currentPrice: product.currentPrice,
                    attachments: product.attachments,
                    catalogueNumber: product.catalogueNumber,
                    status: product.status,
                    descriptionShort: product.descriptionShort,
                    productparameterSet:{}
                }
                for(const key2 in product.productparameterSet.edges){
                    let product_parameter_set = product.productparameterSet.edges[key2].node
                    let value = ''
                    let unit = product_parameter_set.parameter.unit;
                    switch (product_parameter_set.parameter.type) {
                        case "N":
                            value = product_parameter_set.numberValue;
                            break;
                        case "B":
                            value = product_parameter_set.numberValue === 1 ? `Tak` : `Nie`;
                            break;
                        case "T":
                        case "C":
                            value = product_parameter_set.textValue;
                            break;
                        case "A":
                            value = product_parameter_set.arrayValue ? product_parameter_set.arrayValue.join(', ') : value;
                            break;
                        case "R":
                            value = `${product_parameter_set.numberValue} - ${product_parameter_set.numberValueTo}`;
                            break;

                    }
                    if(unit && value !== ''){
                        value = `${value} ${unit}`
                    }
                    tmp_products[product.id].productparameterSet[product_parameter_set.parameter.id]={
                        id: product_parameter_set.parameter.id,
                        name: product_parameter_set.parameter.name,
                        value: value,
                    }
                    tmp_comparsion_parameters[product_parameter_set.parameter.id] = {
                        id: product_parameter_set.parameter.id,
                        name: product_parameter_set.parameter.name
                    }
                    if(tmp_comparsion_parameters_ids.includes(product_parameter_set.parameter.id)===false){
                        tmp_comparsion_parameters_ids.push(product_parameter_set.parameter.id)
                    }
                }
            }
            props.setParameters(tmp_comparsion_parameters_ids)
            setComparsionParameters(tmp_comparsion_parameters)
            setComparsionProducts(tmp_products)
            setColumnWidth(100/(Object.keys(tmp_products).length+1))
        }
    },[props.products_data])

    return(
        <div>
            <table className={`comparsionPreviewTableDesktop ${brand}`}>
                <thead>
                    <tr>
                        <td className="parameter_name" style={{width: `${columnWidth}%`}}>
                            Nazwa parametru
                        </td>
                        {Object.keys(comparsion_products).map((product)=>
                        <td style={{width: `${columnWidth}%`}}>
                            <div>
                                <h3>{comparsion_products[product].name}</h3>
                                <p style={{
                                    width: '80%',
                                    fontSize: '14px',
                                    lineHeight: '14px'
                                }}>
                                    {`Nr kat.: ${comparsion_products[product].catalogueNumber}`}
                                </p>
                                <p style={{
                                    width: '80%',
                                    fontSize: '14px',
                                    lineHeight: '14px'
                                }}>
                                    {comparsion_products[product].descriptionShort}
                                </p>
                                {comparsion_products[product].currentPrice &&
                                    <StyledPrice colorSet={({theme}) => theme.color.primaryColor}
                                                 style={{textAlign: 'left'}}>
                                        {`${comparsion_products[product].currentPrice.value} PLN netto`}
                                    </StyledPrice>
                                }
                                <AvatarPlaceholder file={comparsion_products[product].attachments.edges} category={props.categoryId} />
                            </div>
                        </td>)}
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(comparsion_parameters).map(
                        (parameter_id)=>
                            <tr key={comparsion_parameters[parameter_id].id}>
                                <td className="parameter_name" style={{width: `${columnWidth}%`}}>
                                    {comparsion_parameters[parameter_id].name}
                                </td>
                                {Object.keys(comparsion_products).map(
                                    (product_id)=>
                                        <td style={{width: `${columnWidth}%`}}>
                                            {comparsion_products[product_id].productparameterSet[parameter_id]?
                                                <>
                                                    {comparsion_products[product_id].productparameterSet[parameter_id].value}
                                                </>:
                                                <>-</>
                                            }
                                        </td>
                                )}
                            </tr>
                    )}
                </tbody>
            </table>
    </div>
    )
}

const cookies = new Cookie();

const ComparsionPreview = (props) =>{
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [parameters, setParameters] = useState([])
    const {windowWidth} = useContext(WindowWidthContext);
    const {loading: products_loading, error: products_error, data: products_data, refetch: products_get} = useQuery(GET_COMPARSION_PRODUCTS, {variables:{id:props.comparedProducts, type:"G", count:0}})
    const token = cookies.get('jwttoken');
    const showModal = async () => {
        if(props.comparedProducts.length===0){
            message.error("Nie wybrano produktów do porównania")
        }else{
            setIsModalOpen(true);
            products_get({id:props.comparedProducts, type:"G", count:5})
        }
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };

    function download(blob, filename) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }

    const exportToPDF = async (name) => {
        let body = {
            "products": props.comparedProducts,
            "parameters": parameters
        }
        let response = await fetch(`${process.env.REACT_APP_URL_EXPORT_COMPARSION_AS_PDF}`,
            {
                method: "POST",
                headers: {"Authorization": token},
                body: JSON.stringify(body)
            }
        )
        response.blob().then(blob => {
            download(blob, 'comparsion.pdf')
        })
    };

    const afterCloseHandler = () =>{
        /* TODO: Repair problem with broken scroll by modal
        * This code is here because modal in  ant design broke a scroll on main page by add class and style inside body tag.
        * Probably modal should add it when modal is open.
        * I dont know what is wrong and i must use this hack for repair this problem at this moment
        * */
        document.getElementsByTagName("body")[0].style="";
        document.getElementsByTagName("body")[0].class="";
    }

    return (
        <>
            <Button onClick={showModal} style={{width:"100%", marginBottom:"1.5rem", marginTop:".5rem"}}>
                Pokaż porównanie
            </Button>
            <Modal title="Podgląd porównania"
                   visible={isModalOpen}
                   maskClosable={false}
                   width={"100%"}
                   destroyOnClose={true}
                   afterClose={afterCloseHandler}
                   closable={false}
                   footer={[
                       <Button type="danger" onClick={exportToPDF}>Eksportuj do PDF</Button>,
                       <Button type="primary" onClick={handleOk}>Zamknij</Button>
                   ]}
            >
                {windowWidth === 'mobile' ? <ComparsionMobileTable products_data={products_data} loading={products_loading} setParameters={setParameters} />: <ComparsionDesktopTable products_data={products_data} loading={products_loading} categoryId={props.categoryId} setParameters={setParameters} /> }
            </Modal>
        </>
    );
}

export default ComparsionPreview;