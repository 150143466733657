/**
 * Utility function for removing unpublished categories.
 * @param {Array} categories
 * @returns {Array} List of published products.
 */
export const removeCategoriesWithoutProducts = (categories) => {
  if (categories) {
    return categories.filter(({node}) => {
      const { childrenWithPublishedProducts, isLeaf, publishedProductsCumulativeCount } = node;
      const totalCount = isLeaf ? publishedProductsCumulativeCount : childrenWithPublishedProducts && childrenWithPublishedProducts.totalCount
      return totalCount > 0
    });
  }
  return [];
};
