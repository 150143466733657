import gql from 'graphql-tag'

const EDIT_USER_COMMENT = gql`
    mutation($input: EditUserCommentMutationInput!){
        editUserComment(input: $input){
            updateStatus
        }
    }
`

export default EDIT_USER_COMMENT;