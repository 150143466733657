import React, {useState} from 'react';
import {withApollo} from 'react-apollo';
import LayoutCenter from "../../../containers/LayoutCenter";
import LoginForm from '../../../components/LoginForm';
import LoginKeycloak from '../../../components/LoginKeycloak';
import {Redirect} from 'react-router-dom';
import Cookies from 'universal-cookie';
import FormContainer from "../../../containers/FormContainer";


const LoginKeycloakForm = ({match}) => {
    const cookies = new Cookies();
    const [redirect, setRedirect] = useState(cookies.get('jwttoken') && cookies.get('role'));
    if (redirect) {
        return <Redirect to='/'/>
    }
    return (
        <LayoutCenter>
            <FormContainer>
                <LoginKeycloak brand={match.params.brand} redirect={setRedirect}/>
            </FormContainer>
        </LayoutCenter>
    )
};

LoginKeycloakForm.defaultProps = {};

LoginKeycloakForm.propTypes = {};

export default withApollo(LoginKeycloakForm);