import React from 'react';
import {Button, Input, message, Form} from 'antd';
import {DeleteFilled, EditOutlined, SaveOutlined} from '@ant-design/icons'
import Cookies from "universal-cookie";
import {withTranslation} from "react-i18next";

class FavouriteGroupsManager extends React.Component{
    constructor(props) {
        const cookies = new Cookies();
        super(props);
        this.state = {
            groups: props.groups,
            name_new_group:"",
            brand: cookies.get('selectedBrand'),
            edit_group: false,
            edit_group_name: false
        }
    }
    nameGroupInputHandler = (e) =>{
        this.setState({name_new_group: e.target.value})
    }

    newNameGroupInputHandler = (e) => {
        this.setState({edit_group_name: e.target.value})
    }

    addGroupButtonHandler = () =>{
        if(this.state.name_new_group.length>0){
            this.props.addGroupHandler(this.state.name_new_group)
            this.setState({name_new_group:''})
        }else{
            message.error("Brak nazwy grupy")
        }
    }
    editGroupButtonHandler = (e) =>{
        this.setState({edit_group: e})
    }
    saveEditGroup = () =>{
        this.props.editGroupHandler(this.state.edit_group.id, this.state.edit_group_name)
        this.setState({edit_group: false, edit_group_name: false})
    }

    render(){
        return(
            <div style={{display:"flex", flexDirection:"column", justifyItems:"center", alignSelf:"center"}}>
                <ul style={{listStyle:"none", padding:"1px"}}>
                {this.props.groups.map(
                    (group) => <li style={{display: "flex", flexDirection:"row", justifyContent: "space-between"}}><div style={{textOverflow:"ellipsis", whiteSpace:"nowrap", maxWidth:"50%"}} key={group.node.id}>{group.node.name}</div><div><Button onClick={()=>this.editGroupButtonHandler(group.node)}><EditOutlined /></Button><Button onClick={()=> {this.props.deleteGroupHandler(group.node.id)}} style={{color: "red"}}><DeleteFilled /></Button></div></li>
                )}
                </ul>
                <Input.Group compact>
                    <Input style={{ width: '80%' }} onChange={this.nameGroupInputHandler} placeholder={this.props.t('add-a-new-group')} value={this.state.name_new_group}/>
                    <Button onClick={this.addGroupButtonHandler} type="primary">+</Button>
                </Input.Group>
                {this.state.edit_group &&
                    <Form>
                        {this.props.t('edit-group')}:
                        <Input.Group compact>
                            <Input name="new_value" onChange={this.newNameGroupInputHandler} defaultValue={this.state.edit_group.name} key={this.state.edit_group.id} style={{ width: '80%' }} />
                            <Button onClick={this.saveEditGroup}><SaveOutlined /></Button>
                        </Input.Group>
                    </Form>
                }
            </div>
        )
    }
}

export default withTranslation('favouriteProducts')(FavouriteGroupsManager)