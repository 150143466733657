import gql from 'graphql-tag'

const MANAGE_PRODUCT_IN_CART = gql`
    mutation($input: ManageProductInCartInput!){
        manageProductInCart(input: $input){
            changed,
            deleted
        }
    }
`

export default MANAGE_PRODUCT_IN_CART;